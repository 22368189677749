import React from 'react';
import { Accordion } from 'semantic-ui-react';
import Icon from '@plone/volto/components/theme/Icon/Icon';
import rightSVG from '@plone/volto/icons/right-key.svg';
import downSVG from '@plone/volto/icons/down-key.svg';
import AnimateHeight from 'react-animate-height';
import RangeSliderWidget from './RangeSliderWidget';

const TimeRange = (props) => {
  const {
    block,
    data,
    currentActiveFieldset,
    handleCurrentActiveFieldset,
    refreshPlayer,
    onChangeBlock,
  } = props;

  return (
    <div
      key="blockform-fieldset-trim-video"
      id="blockform-fieldset-trim-video"
      className="videojs-sidebar-trim-video"
    >
      <Accordion.Title
        active={currentActiveFieldset === 2}
        index={2}
        onClick={handleCurrentActiveFieldset}
      >
        <>Time range</>
        {currentActiveFieldset === 2 ? (
          <Icon name={downSVG} size="20px" />
        ) : (
          <Icon name={rightSVG} size="20px" />
        )}
      </Accordion.Title>
      <Accordion.Content active={currentActiveFieldset === 2}>
        <AnimateHeight
          animateOpacity
          duration={500}
          height={currentActiveFieldset === 2 ? 'auto' : 2}
        >
          <RangeSliderWidget
            id="timeRange"
            curMin={
              data.options?.['timeRange'] ? data.options?.['timeRange'].min : 0
            }
            curMax={
              data.options?.['timeRange']
                ? data.options?.['timeRange'].max
                : data.options?.duration || 60
            }
            onChange={(id, value) => {
              onChangeBlock(block, {
                ...data,
                options: {
                  ...data.options,
                  [id]: value,
                },
              });
              refreshPlayer();
            }}
            min={0}
            max={data.options?.duration || 60}
            key="timeRange"
            block={block}
            title="Time range"
            description="Virtually cut video"
          />
        </AnimateHeight>
      </Accordion.Content>
    </div>
  );
};

export default TimeRange;
