const getVar = (varName, defaultValue) => {
  var result = defaultValue;
  if (process.env?.[varName]) {
    result = process.env[varName];
  } else {
    if (typeof window !== 'undefined' && window.env?.[varName]) {
      result = window.env[varName];
    }
  }
  return result;
};

const dateTimeStringAppendZ = (dateTimeString) => {
  // Append Z timezone to datetime string if it doesn't have any
  // This is needed since Plone rest api returns datetimes in UTC without timezone
  // if the datetime was initially saved trough rest api
  const timePattern = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d((\d\.[\d]{3})|\d)$/;
  if (timePattern.test(dateTimeString)) {
    return dateTimeString + 'Z';
  }
  return dateTimeString;
};

export { getVar, dateTimeStringAppendZ };
