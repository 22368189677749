/**
 * Routes.
 * @module routes
 */

import App from '@plone/volto/components/theme/App/App';
import { defaultRoutes } from '@plone/volto/routes';
import Move from '@package/components/manage/Move/Move';
import Embed from '@package/components/manage/Embed/Embed';
import config from '@plone/volto/registry';

import { EPICS_TASK_VIEWS } from 'volto-epics-addon/constants';
import {
  TASK_UPLOAD_PROMPT,
  TASK_UPLOAD_BASICINFO_PROMPT,
  TASK_UPLOAD_WAITFORJOBS_VIEW,
  TASK_INTERMEDIATE_JOBS_VIEW,
  TASK_UPLOAD_DETAILS_PROMPT,
} from 'volto-base-addon/constants';
import UploadStartPage from '@package/components/theme/FileUpload/UploadStartPage';
import UploadPromptPage from '@package/components/theme/FileUpload/UploadPromptPage';
import UploadBasicInfoPromptPage from '@package/components/theme/FileUpload/UploadBasicInfoPromptPage';
import UploadWaitForJobsPage from '@package/components/theme/FileUpload/UploadWaitForJobsPage';
import WaitIntermediateJobsPage from '@package/components/theme/FileUpload/WaitIntermediateJobsPage';
import UploadDetailsPromptPage from '@package/components/theme/FileUpload/UploadDetailsPromptPage';

/**
 * Routes array.
 * @array
 * @returns {array} Routes.
 */

const routes = [
  {
    path: '/embed',
    component: Embed,
    routes: [],
  },
  {
    path: '/',
    component: App, // Change this if you want a different component
    routes: [
      {
        path: '/file-upload',
        component: UploadStartPage,
      },
      {
        path: EPICS_TASK_VIEWS[TASK_UPLOAD_PROMPT],
        component: UploadPromptPage,
      },
      {
        path: EPICS_TASK_VIEWS[TASK_UPLOAD_BASICINFO_PROMPT],
        component: UploadBasicInfoPromptPage,
      },
      {
        path: EPICS_TASK_VIEWS[TASK_UPLOAD_WAITFORJOBS_VIEW],
        component: UploadWaitForJobsPage,
      },
      {
        path: EPICS_TASK_VIEWS[TASK_INTERMEDIATE_JOBS_VIEW],
        component: WaitIntermediateJobsPage,
      },
      {
        path: EPICS_TASK_VIEWS[TASK_UPLOAD_DETAILS_PROMPT],
        component: UploadDetailsPromptPage,
      },
      {
        path: '/**/move',
        component: Move,
      },
      ...(config.addonRoutes || []),
      ...defaultRoutes,
    ],
  },
];

export default routes;
