import React from 'react';
import { Accordion, Segment, Tab } from 'semantic-ui-react';
import Icon from '@plone/volto/components/theme/Icon/Icon';
import rightSVG from '@plone/volto/icons/right-key.svg';
import downSVG from '@plone/volto/icons/down-key.svg';
import AnimateHeight from 'react-animate-height';
import Source from './Panes/Source';
import Options from './Panes/Options';

const PlayerConfig = (props) => {
  const {
    currentActiveFieldset,
    handleCurrentActiveFieldset,
    videoSourceExist,
  } = props;

  const panes = [
    {
      menuItem: 'Sources',
      render: () => <Source {...props} />,
    },
    {
      menuItem: 'Options',
      render: () => <Options {...props} />,
    },
  ];

  return (
    <div key="configuration" id="blockform-fieldset-configuration">
      <Accordion.Title
        active={currentActiveFieldset === 0}
        index={0}
        onClick={handleCurrentActiveFieldset}
      >
        <>Configuration</>
        {currentActiveFieldset === 0 ? (
          <Icon name={downSVG} size="20px" />
        ) : (
          <Icon name={rightSVG} size="20px" />
        )}
      </Accordion.Title>
      <Accordion.Content active={currentActiveFieldset === 0}>
        <AnimateHeight
          animateOpacity
          duration={500}
          height={currentActiveFieldset === 0 ? 'auto' : 0}
        >
          <Segment className="attached">
            <Tab
              panes={
                videoSourceExist() && props.type !== 'mediapagevideojs'
                  ? panes
                  : videoSourceExist() && props.type === 'mediapagevideojs'
                    ? [panes[1]]
                    : [panes[0]]
              }
            />
          </Segment>
        </AnimateHeight>
      </Accordion.Content>
    </div>
  );
};

export default PlayerConfig;
