import React from 'react';
import {
  Popup,
  Label,
  Grid,
  Form,
  FormGroup,
  FormRadio,
  Accordion,
  Button,
  Icon as SIcon,
  Segment,
  TextArea,
} from 'semantic-ui-react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Toast from '@plone/volto/components/manage/Toast/Toast';
import Icon from '@plone/volto/components/theme/Icon/Icon';
import rightSVG from '@plone/volto/icons/right-key.svg';
import downSVG from '@plone/volto/icons/down-key.svg';
import { getContent } from '@plone/volto/actions/content/content';
import config from '@plone/volto/registry';
import jwtDecode from 'jwt-decode';
import request from 'superagent';
import { MEDIA_PAGE_SUBTITLES_ROUTE } from 'volto-base-addon/constants';
import has from 'lodash/has';
import cloneDeep from 'lodash/cloneDeep';
import omit from 'lodash/omit';
import { v4 as uuid } from 'uuid';

const SubtitlesEditor = ({ playerInstance, parentUid }) => {
  const dispatch = useDispatch();
  const [textTrackChanges, setTextTrackChanges] = React.useState({});
  const [activeCues, setActiveCues] = React.useState([]);
  const [currentTextTrack, setCurrentTextTrack] = React.useState(null);
  const [lockedTracks, setLockedTracks] = React.useState({});
  const [editorMode, setEditorMode] = React.useState('p');
  const [originalPlainText, setOriginalPlainText] = React.useState('');
  const [editablePlainText, setEditablePlainText] = React.useState('');
  const [subtitlesError, setSubtitlesError] = React.useState(null);

  const [currentActiveAcc, setCurrentActiveAcc] = React.useState(-1);
  const currentUser = useSelector((state) =>
    state.userSession.token ? jwtDecode(state.userSession.token).sub : '',
  );

  const getParser = (addCues = true) => {
    setSubtitlesError(null);

    const parser = new window.WebVTT.Parser(
      window,
      window.vttjs,
      window.WebVTT.StringDecoder(),
    );

    parser.oncue = (cue) => {
      if (addCues) {
        cue.id = uuid();
        currentTextTrack.addCue(cue);
      }
    };

    parser.onparsingerror = (error) => {
      setSubtitlesError(error.message);
    };

    parser.onflush = () => {};

    return parser;
  };

  const getSubtitlesPresignedUrl = async (mediapageUid, subtitlesId) => {
    const { body } = await request.get(
      `${config.settings.publicPath}/${MEDIA_PAGE_SUBTITLES_ROUTE}/${mediapageUid}/put/${subtitlesId}`,
    );

    if (has(body, 'presigned_url')) {
      return body;
    }

    return null;
  };

  const buildSubtitlesPlainText = (cues) => {
    let text = 'WEBVTT\n';
    cues.forEach((cue) => {
      text = text.concat(
        `\n${formatSeconds(cue.startTime)} --> ${formatSeconds(cue.endTime)}\n`,
      );
      text = text.concat(`${cue.text}\n`);
    });

    setOriginalPlainText(text);
    setEditablePlainText(text);
  };

  React.useEffect(() => {
    resetSubtitlesEditorVariables();

    if (playerInstance) {
      if (playerInstance.remoteTextTracks().length) {
        const textTrackList = playerInstance.remoteTextTracks();
        textTrackList.addEventListener('change', () => {
          const activeTextTrack = textTrackList.tracks_.find(
            (item) => item.mode === 'showing',
          );
          if (activeTextTrack) {
            setCurrentTextTrack(activeTextTrack);
            buildSubtitlesPlainText(activeTextTrack.cues_);
            setActiveCueList(activeTextTrack);
          } else {
            setCurrentTextTrack(null);
            setOriginalPlainText('');
            setEditablePlainText('');
            setActiveCues([]);
          }
        });

        textTrackList.tracks_.forEach((track) => {
          const subtitlesContentPath = track.id.substring(
            config.settings.remoteTextTrackIdPrefix.length,
          );
          // Only if it's our own subtitle
          if (subtitlesContentPath.includes('/')) {
            dispatch(getContent(subtitlesContentPath, null, 'compare_to', null))
              .then((content) => {
                const lock = content.lock;
                const creator = lock?.creator_name || lock?.creator;
                if (lock !== undefined) {
                  if (lock.locked && creator !== currentUser) {
                    setLockedTracks((prevState) => {
                      return {
                        ...prevState,
                        [track.id]: {
                          creator: creator,
                        },
                      };
                    });
                  } else {
                    track.addEventListener('cuechange', () => {
                      if (track.activeCues.length) {
                        setActiveCueList(track);
                      }
                    });
                  }
                }
              })
              // eslint-disable-next-line no-console
              .catch((error) => console.log(error));
          }
        });

        // On every time change,
        // try to update active cues.
        playerInstance.on('timeupdate', () => {
          const activeTextTrack = textTrackList.tracks_.find(
            (item) => item.mode === 'showing',
          );
          if (activeTextTrack === undefined) {
            return;
          }
          setActiveCueList(activeTextTrack);
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [playerInstance]);

  const setActiveCueList = (track) => {
    const currentTime = playerInstance.currentTime();
    const cueInterval = config.settings.subtitlesCueInterval;

    const activeCues = [];
    track.cues_.forEach((cue, cueIdx) => {
      // Do not include cue if outside of visible region
      if (
        cue.startTime > currentTime + cueInterval ||
        cue.endTime < currentTime - cueInterval
      ) {
        return;
      }
      activeCues.push(cue);
    });
    setActiveCues(activeCues);
  };

  function handleCurrentActiveAcc(e, accordion) {
    const { index } = accordion;
    const newIndex = currentActiveAcc === index ? -1 : index;

    setCurrentActiveAcc(newIndex);
  }

  const resetSubtitlesEditorVariables = () => {
    setLockedTracks({});
    setActiveCues([]);
    setCurrentTextTrack(null);
    setTextTrackChanges({});
  };

  const updateTextTrackChanges = () => {
    if (!textTrackChanges[currentTextTrack.id]) {
      setTextTrackChanges((prevState) => {
        return {
          ...prevState,
          [currentTextTrack.id]: {
            originalVersion: cloneDeep(currentTextTrack.cues_),
          },
        };
      });
    } else {
      setTextTrackChanges((prevState) => {
        return {
          ...prevState,
          [currentTextTrack.id]: {
            ...textTrackChanges[currentTextTrack.id],
          },
        };
      });
    }

    currentTextTrack.mode = 'hidden';
    currentTextTrack.mode = 'showing';
  };

  const onPlainTextChange = (text) => {
    if (text !== '') {
      setEditablePlainText(text);
      // Just validate
      const parser = getParser(false);
      parser.parse(text);
      parser.flush();
    }
  };

  const onInteractiveTextChange = (cue, value) => {
    cue.text = value;

    updateTextTrackChanges();
  };

  const setStartTime = (cue) => {
    cue.startTime = playerInstance.currentTime();

    updateTextTrackChanges();
  };

  const setEndTime = (cue) => {
    updateTextTrackChanges();

    cue.endTime = playerInstance.currentTime();
    currentTextTrack.mode = 'hidden';
    currentTextTrack.mode = 'showing';
  };

  const deleteCue = (cue) => {
    updateTextTrackChanges();

    const cueIndex = activeCues.indexOf(cue);
    setActiveCues(activeCues.splice(cueIndex, 1));
    currentTextTrack.removeCue(cue);
    currentTextTrack.mode = 'hidden';
    currentTextTrack.mode = 'showing';
  };

  const createEmptyCue = () => {
    const currentTime = playerInstance.currentTime();

    const start = Math.floor(currentTime);
    const end = Math.floor(currentTime);

    // Check that no overlapping cues exist
    var valid = true;
    currentTextTrack.cues_.forEach((cue, cueIdx) => {
      if (cue.startTime <= end && cue.endTime >= start) {
        valid = false;
      }
    });

    if (!valid) return;

    updateTextTrackChanges();

    var cue = new VTTCue(start, end, 'Add text here.');

    // Cant just use addCue once, as that pushes to the end..

    const newCues = currentTextTrack.cues_.concat([cue]);
    newCues.sort((a, b) => {
      return a.startTime - b.startTime;
    });

    var i = currentTextTrack.cues.length;
    while (i--) {
      currentTextTrack.removeCue(currentTextTrack.cues[i]);
    }
    newCues.forEach((cue) => {
      currentTextTrack.addCue(cue);
    });

    setActiveCueList(currentTextTrack);

    currentTextTrack.mode = 'hidden';
    currentTextTrack.mode = 'showing';
  };

  const formatSeconds = (seconds) => {
    if (isNaN(seconds)) return '00:00.000';

    const hours = Math.floor(seconds / 3600);
    const min = Math.floor((seconds % 3600) / 60);
    const sec = Math.floor(seconds % 60);
    const mill = Math.round((seconds % 1) * 1000);

    if (hours > 0) {
      return `${formatTimeUnit(hours, 2)}:${formatTimeUnit(
        min,
        2,
      )}:${formatTimeUnit(sec, 2)}.${formatTimeUnit(mill, 3)}`;
    } else {
      return `${formatTimeUnit(min, 2)}:${formatTimeUnit(
        sec,
        2,
      )}.${formatTimeUnit(mill, 3)}`;
    }
  };

  function formatTimeUnit(unit, width) {
    if (!unit) return '0'.repeat(width);
    return unit.toString().padStart(width, '0');
  }

  const saveSubtitleChanges = async () => {
    if (editorMode === 'p') {
      while (currentTextTrack.cues_.length) {
        currentTextTrack.removeCue(currentTextTrack.cues_[0]);
      }
      const parser = getParser();
      parser.parse(editablePlainText);
      parser.flush();
      updateTextTrackChanges();
    }
    let result = 'WEBVTT';
    currentTextTrack.cues_
      .filter((cue) => cue.startTime !== null && cue.endTime !== null)
      .sort((a, b) => {
        const startDiff = a.start - b.start;
        if (a.start === null) {
          return 1;
        }
        if (b.start === null) {
          return -1;
        }
        if (startDiff === 0) {
          return a.end - b.end;
        }
        return startDiff;
      })
      .forEach((cue) => {
        result +=
          '\n\n' +
          formatSeconds(cue.startTime) +
          ' --> ' +
          formatSeconds(cue.endTime) +
          '\n' +
          cue.text;
      });

    const subtitlesContentPath = currentTextTrack.id.substring(
      config.settings.remoteTextTrackIdPrefix.length,
    );

    dispatch(getContent(subtitlesContentPath, null, 'compare_to', null))
      .then(async (content) => {
        const { presigned_url } = await getSubtitlesPresignedUrl(
          parentUid,
          content[config.settings.subtitlesId],
        );

        if (presigned_url) {
          request
            .put(presigned_url)
            .set('Content-Type', 'text/plain')
            .send(result)
            .then(() => {
              setTextTrackChanges((prevState) => {
                return {
                  ...omit(prevState, currentTextTrack.id),
                };
              });
              toast.success(
                <Toast
                  error
                  title="Success"
                  content="Subtitle changes saved"
                />,
              );
            })
            .catch(() => {
              toast.error(
                <Toast error title="Error" content="Subtitles saving error" />,
              );
            });
        }
      })
      // eslint-disable-next-line no-console
      .catch((error) => console.log(error));
  };

  const resetSubtitles = () => {
    if (editorMode === 'p') {
      setEditablePlainText(originalPlainText);
      setSubtitlesError(null);
    } else {
      if (textTrackChanges[currentTextTrack.id]?.originalVersion) {
        buildSubtitlesPlainText(
          textTrackChanges[currentTextTrack.id]?.originalVersion,
        );
        currentTextTrack.cues_ = cloneDeep(
          textTrackChanges[currentTextTrack.id].originalVersion,
        );

        setTextTrackChanges((prevState) => {
          return {
            ...omit(prevState, currentTextTrack.id),
          };
        });
        setSubtitlesError(null);
        currentTextTrack.mode = 'hidden';
        currentTextTrack.mode = 'showing';
      }
    }
  };

  const changeEditorMode = (mode) => {
    setEditorMode(mode);
  };

  return (
    <>
      {!currentTextTrack ? (
        <span
          style={{
            display: 'block',
            padding: '1em',
          }}
        >
          Subtitle editor is not shown as there is no subtitle track selected.
        </span>
      ) : null}
      {currentTextTrack && lockedTracks[currentTextTrack.id] ? (
        <span
          style={{
            display: 'block',
            padding: '1em',
          }}
        >
          Subtitle editor for ({currentTextTrack.label}) is locked by{' '}
          {lockedTracks[currentTextTrack.id].creator}
        </span>
      ) : null}
      {currentTextTrack && !lockedTracks[currentTextTrack.id] ? (
        <Accordion fluid styled className="form" key={currentTextTrack.id}>
          <Accordion.Title
            active={currentActiveAcc === 0}
            index={0}
            onClick={handleCurrentActiveAcc}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <span>
              {currentTextTrack.label}{' '}
              {(textTrackChanges[currentTextTrack.id] ||
                editablePlainText !== originalPlainText) && (
                <span style={{ color: 'red' }}>
                  {editorMode === 'p'
                    ? '*Unsaved changes will be applied after saving'
                    : '*Unsaved'}
                </span>
              )}
            </span>
            {currentActiveAcc === 0 ? (
              <Icon name={downSVG} size="20px" />
            ) : (
              <Icon name={rightSVG} size="20px" />
            )}
          </Accordion.Title>
          <Accordion.Content active={currentActiveAcc === 0}>
            <div
              style={{
                textAlign: 'left',
              }}
            >
              {!activeCues.length ? (
                <span>
                  {' '}
                  There are no subtitles close to the current timepoint.
                </span>
              ) : (
                <Form className="subtitles-editor-mode-form">
                  <FormGroup inline>
                    <b style={{ marginRight: '1em' }}>Editor Mode:</b>
                    <FormRadio
                      className="subtitles-editor-mode-radio"
                      label="Text"
                      value="p"
                      checked={editorMode === 'p'}
                      disabled={
                        editorMode === 'i' &&
                        textTrackChanges[currentTextTrack.id] !== undefined
                      }
                      onChange={(e, { value }) => changeEditorMode(value)}
                    />
                    <FormRadio
                      className="subtitles-editor-mode-radio"
                      label="Interactive"
                      value="i"
                      disabled={
                        editorMode === 'p' &&
                        editablePlainText !== originalPlainText
                      }
                      checked={editorMode === 'i'}
                      onChange={(e, { value }) => changeEditorMode(value)}
                    />
                  </FormGroup>
                </Form>
              )}
              {editorMode === 'p' && (
                <Form className="subtitles-editor-textform">
                  {subtitlesError && (
                    <label
                      style={{
                        display: 'block',
                        textAlign: 'center',
                        color: 'red',
                      }}
                    >
                      {subtitlesError}
                    </label>
                  )}
                  <TextArea
                    style={{
                      border: `${subtitlesError ? '1px solid red' : 'none'}`,
                      minHeight: '300px',
                    }}
                    value={editablePlainText}
                    onChange={(e, { value }) => onPlainTextChange(value)}
                  />
                </Form>
              )}
              {editorMode === 'i' &&
                activeCues.map((cue, index) => (
                  <Segment key={cue.id}>
                    <Grid stackable>
                      <Grid.Row>
                        <Grid.Column>
                          <Form className="subtitles-editor-form">
                            <Form.Group>
                              <Form.Field width={6}>
                                <Segment
                                  style={{
                                    padding: '10px',
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                  }}
                                >
                                  <Popup
                                    size="tiny"
                                    trigger={
                                      <Label
                                        className="subtitle-time-label"
                                        onClick={() => {
                                          setStartTime(cue);
                                        }}
                                      >
                                        Start
                                        <SIcon
                                          style={{
                                            margin: '5px',
                                          }}
                                          name="time"
                                        />
                                      </Label>
                                    }
                                    content="Set start time"
                                    position="top left"
                                  />
                                  {formatSeconds(cue.startTime)}
                                </Segment>
                              </Form.Field>
                              <Form.Field width={6}>
                                <Segment
                                  style={{
                                    padding: '10px',
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                  }}
                                >
                                  {formatSeconds(cue.endTime)}
                                  <Popup
                                    size="tiny"
                                    trigger={
                                      <Label
                                        className="subtitle-time-label"
                                        onClick={() => {
                                          setEndTime(cue);
                                        }}
                                      >
                                        <SIcon
                                          style={{
                                            margin: '5px',
                                          }}
                                          name="time"
                                        />
                                        End
                                      </Label>
                                    }
                                    content="Set end time"
                                    position="top left"
                                  />
                                </Segment>
                              </Form.Field>
                              <Form.Field>
                                <Segment
                                  style={{
                                    padding: '10px',
                                    display: 'flex',
                                    height: '100%',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                  }}
                                >
                                  <Popup
                                    size="tiny"
                                    trigger={
                                      <Button
                                        size="mini"
                                        color="red"
                                        icon="delete"
                                        style={{
                                          border: '0',
                                          cursor: 'pointer',
                                          outline: '0',
                                          userSelect: 'none',
                                          MozAppearance: 'none',
                                          WebkitAppearance: 'none',
                                          WebkitTapHighlightColor:
                                            'transparent',
                                          margin: 0,
                                        }}
                                        onClick={() => deleteCue(cue)}
                                      ></Button>
                                    }
                                    content="Delete"
                                    position="top left"
                                  />
                                </Segment>
                              </Form.Field>
                            </Form.Group>
                            <Form.TextArea
                              label="Text"
                              placeholder="Tell us more about you..."
                              value={cue.text}
                              onChange={(event) => {
                                onInteractiveTextChange(
                                  cue,
                                  event.target.value,
                                );
                              }}
                            />
                          </Form>
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                  </Segment>
                ))}
            </div>
            <div
              style={{
                display: 'flex',
                marginTop: '20px',
                justifyContent: 'right',
              }}
            >
              {editorMode !== 'p' && (
                <Button onClick={createEmptyCue}>Add new row</Button>
              )}
              <Button onClick={resetSubtitles}>Cancel</Button>
              <Button
                primary
                disabled={
                  (editorMode === 'p' &&
                    (subtitlesError !== null ||
                      editablePlainText === originalPlainText)) ||
                  (editorMode === 'i' &&
                    (subtitlesError !== null ||
                      !textTrackChanges[currentTextTrack.id]))
                }
                onClick={saveSubtitleChanges}
              >
                Save
              </Button>
            </div>
          </Accordion.Content>
        </Accordion>
      ) : null}
    </>
  );
};

export default SubtitlesEditor;
