import React, { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getQuerystring } from '@package/actions/scopedQuerystring/scopedQuerystring';
import { flattenToAppURL, getBaseUrl } from '@plone/volto/helpers/Url/Url';

function getDisplayName(WrappedComponent) {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}

/**
 * A HOC that injects scoped querystring metadata information from the backend.
 *
 */
export default function withScopedQueryString(WrappedComponent) {
  function WithScopedQueryString(props) {
    const dispatch = useDispatch();
    let initialized = useRef(false);

    const qs = useSelector((state) => state.scopedQuerystring);
    const content_id = useSelector((state) => state.content?.data['@id']);
    const pathname = getBaseUrl(flattenToAppURL(content_id || ''));
    const query_id = getBaseUrl(flattenToAppURL(qs?.id || ''));
    const previous_time = qs?.time || null;
    const current_time = Date.now();
    const should_update = previous_time
      ? current_time > previous_time + 5000
      : true;

    React.useEffect(() => {
      var url = `${pathname}/@querystring`;
      if (query_id !== url || (!initialized.current && should_update)) {
        dispatch(getQuerystring(pathname));
      }
      initialized.current = true;
    }, [dispatch, query_id, pathname, should_update]);

    return <WrappedComponent {...props} querystring={qs} />;
  }
  WithScopedQueryString.displayName = `WithScopedQueryString(${getDisplayName(
    WrappedComponent,
  )})`;
  return WithScopedQueryString;
}
