import React, { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Button } from 'semantic-ui-react';
import Api from '@plone/volto/helpers/Api/Api';
import { flattenToAppURL } from '@plone/volto/helpers/Url/Url';
import styles from './styles.module.scss';
import shareicon from './google_plus_reshare.svg';
import dowloadicon from './download.svg';
import Disclaimer from '@package/components/manage/Downloads/Disclaimer';

function customSort(a, b) {
  // Function to check if the title is numeric (resolution)
  const isNumeric = (str) => /\d+p$/.test(str); // Ends with a number followed by 'p'

  const isNumericA = isNumeric(a.title);
  const isNumericB = isNumeric(b.title);

  // Group all non-numeric titles together and sort them alphabetically
  if (!isNumericA && isNumericB) return 1; // Non-numeric titles go after numeric ones
  if (isNumericA && !isNumericB) return -1; // Numeric titles come before non-numeric

  // If both are numeric, sort by the numeric value
  if (isNumericA && isNumericB) {
    const valueA = parseInt(a.title.match(/\d+/)[0], 10);
    const valueB = parseInt(b.title.match(/\d+/)[0], 10);
    return valueA - valueB;
  }

  // If both are non-numeric, sort alphabetically
  return a.title.localeCompare(b.title);
}

const Downloads = (props) => {
  const { showDisclaimer, showShareButton } = props;
  const [downloadItems, setDownloadItems] = useState([]);
  const [selectedItemId, setSelectedItemId] = useState(null);
  //const [isAcknowledged, setIsAcknowledged] = useState(false);
  const content = useSelector((state) => state.content.data);
  const { token } = useSelector((state) => state.userSession);
  const api = new Api();

  const videotitle = content.title;

  useEffect(() => {
    (async () => {
      const contentItems = content.items.filter((item) =>
        ['transcoding', 'subtitle'].includes(item['@type']),
      );

      const newDownloadItems = [];
      for (const item of contentItems) {
        // Get the full object
        const resp = await api.get(flattenToAppURL(item['@id']), {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        // Also get the workflow state
        const workflow = await api.get(
          flattenToAppURL(item['@id']) + '/@workflow',
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        );

        const state =
          workflow?.['chain']?.['media_output_workflow']?.['state']?.['id'];

        if (state !== 'completed') {
          continue;
        }

        // Add to the visible elements
        newDownloadItems.push({
          id: resp.id,
          title: resp.title,
          type: resp['@type'],
          s3_object_id:
            resp['@type'] === 'transcoding'
              ? resp.s3_object_id
              : resp.s3_subtitles_id,
          s3_object_path: content.UID,
        });

        // Add duplicate item for subtitles that allows downloading without timestamps
        if (item['@type'] === 'subtitle') {
          newDownloadItems.push({
            id: `${resp.id}-no-timestamps`,
            title: `${resp.title} (no timestamps)`,
            type: `subtitle`,
            s3_object_id: resp.s3_subtitles_id,
            s3_object_path: content.UID,
          });
        }
      }
      setDownloadItems(newDownloadItems);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const downloadButtonHandler = async (event) => {
    event.preventDefault();

    /*  if (!selectedItemId || !isAcknowledged) {
      return;
    }*/

    const selectedItem = downloadItems.filter(
      (item) => item.id === selectedItemId,
    )[0];

    const url = flattenToAppURL(content['@id']) + '/@download-presigned';
    const resp = await api.post(url, {
      data: {
        s3_object_id: selectedItem.s3_object_id,
        s3_object_path: selectedItem.s3_object_path,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (!resp.presigned_url) {
      // eslint-disable-next-line no-console
      console.error(resp.error);
      return;
    }

    // For subtitles, fetch the content and optionally process before triggering download.
    if (selectedItem.type === 'subtitle') {
      try {
        const urlParts = new URL(resp.presigned_url);

        const response = await fetch(resp.presigned_url);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        let content = await response.text();
        let fileName = urlParts.pathname.split('/').pop();
        let type = 'text/vtt';

        if (selectedItemId.endsWith('-no-timestamps')) {
          const timestampRegex =
            /^(\d{2}:)?\d{2}:\d{2}\.\d{3} --> (\d{2}:)?\d{2}:\d{2}\.\d{3}$/;

          // Process the VTT content to remove timestamps
          content = content
            .split('\n')
            .filter((line, index) => index > 1 && !line.match(timestampRegex))
            .join('\n');

          fileName = fileName.replace('.vtt', '-no-timestamps.txt');
          type = 'text/plain';
        }

        const blob = new Blob([content], { type: type });

        // Create a temporary anchor element and trigger the download
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        // Release the object URL
        URL.revokeObjectURL(link.href);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(
          'Error fetching or processing the subtitle file: ',
          error,
        );
      }
      return;
    }

    // Otherwise redirect user to the presigned url.
    window.location.href = resp.presigned_url;
  };

  const [isSharePopupOpen, setIsSharePopupOpen] = useState(false);
  const [isDownloadPopupOpen, setIsDownloadPopupOpen] = useState(false);
  const [isEmbedFieldOpen, setEmbedFieldOpen] = useState(false);

  // Function for opening popup
  const toggleSharePopup = () => {
    setIsSharePopupOpen(!isSharePopupOpen);
  };

  const toggleDownloadPopup = () => {
    setIsDownloadPopupOpen(!isDownloadPopupOpen);
  };
  const toggleEmbedField = () => {
    setEmbedFieldOpen(!isEmbedFieldOpen);
  };

  const [currentUrl, setCurrentUrl] = useState('');

  useEffect(() => {
    // check if the window is available
    if (typeof window !== 'undefined') {
      setCurrentUrl(window.location.href);
    }
  }, []);

  const [showToastEmbed, setShowToastEmbed] = useState(false);
  const [showToastUrl, setShowToastUrl] = useState(false);
  const inputRef = useRef(null);

  const copyToClipboard = () => {
    if (inputRef.current) {
      inputRef.current.select();
      inputRef.current.setSelectionRange(0, 99999); // Mobile support

      navigator.clipboard
        .writeText(currentUrl)
        .then(() => {
          // Show toast notification
          setShowToastUrl(true);
          setTimeout(() => setShowToastUrl(false), 3000);
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.error('Kopiointi epäonnistui: ', err);
        });
    }
  };

  const textareaRef = useRef(null);

  const handleCopy = () => {
    if (textareaRef.current) {
      textareaRef.current.select();
      textareaRef.current.setSelectionRange(0, 99999); // Mobile support

      navigator.clipboard
        .writeText(textareaRef.current.value)
        .then(() => {
          setShowToastEmbed(true);
          setTimeout(() => setShowToastEmbed(false), 3000);
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.error('Kopiointi epäonnistui: ', err);
        });
    }
  };

  const shareToWhatsApp = () => {
    const whatsappUrl = `https://api.whatsapp.com/send?text=Katso%20tämä%20Moniviestin-video%20${encodeURIComponent(
      videotitle,
    )}%3A%20${encodeURIComponent(currentUrl)}`;
    window.open(whatsappUrl, '_blank');
  };

  const shareToFacebook = () => {
    const facebookUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
      currentUrl,
    )}`;
    window.open(facebookUrl, '_blank');
  };

  const shareToTwitter = () => {
    const tweetText = `Katso tämä Moniviestin-video: ${videotitle}: `;
    const twitterUrl = `https://twitter.com/intent/tweet?text=${encodeURIComponent(
      tweetText,
    )}${encodeURIComponent(currentUrl)}`;
    window.open(twitterUrl, '_blank');
  };
  const shareToEmail = () => {
    const subject = 'Katso tämä Moniviestin-video!';
    const body = `Katso tämä Moniviestin-video ${videotitle}: ${currentUrl}`;
    const mailtoUrl = `mailto:?subject=${encodeURIComponent(
      subject,
    )}&body=${encodeURIComponent(body)}`;
    window.location.href = mailtoUrl;
  };

  const closeAll = () => {
    setEmbedFieldOpen(false); // closes embed field
    toggleSharePopup(); // closes share popup
  };

  useEffect(() => {
    // Varmistaa, että toastit poistetaan, kun näkymä vaihtuu
    return () => {
      setShowToastEmbed(false);
      setShowToastUrl(false);
    };
  }, []);

  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };

  useEffect(() => {
    if (isChecked) {
      const timeElement = document.querySelector('.vjs-current-time-display');
      if (timeElement) {
        const time = timeElement.textContent;
        setTimeValue(time);
      }
    } else {
      setTimeValue('');
    }
  }, [isChecked]);

  const [timeValue, setTimeValue] = useState('');

  useEffect(() => {
    if (typeof window !== 'undefined' && timeValue) {
      const totalSeconds = convertTimeToSeconds(timeValue);
      let url = new URL(window.location.href);
      url.searchParams.set('t', totalSeconds);
      setCurrentUrl(url.toString());
    }
  }, [timeValue]);

  const handleTimeChange = (e) => {
    setTimeValue(e.target.value);
  };

  const convertTimeToSeconds = (time) => {
    const parts = time.split(':');
    if (parts.length === 1) {
      // Jos käyttäjä syöttää vain minuutit, esim. "12", muutetaan se sekunneiksi
      return parseInt(parts[0], 10) * 60;
    } else if (parts.length === 2) {
      // Jos käyttäjä syöttää muodossa "mm:ss"
      const minutes = parseInt(parts[0], 10);
      const seconds = parseInt(parts[1], 10);
      return minutes * 60 + seconds;
    }
    return 0;
  };

  return (
    downloadItems.length > 0 && (
      <div className="downloads-container block">
        <div className={styles.downloadsContainer}>
          {showShareButton && (
            <button className={styles.button} onClick={toggleSharePopup}>
              Jaa
              <img src={shareicon} alt="Icon" className={styles.icon} />
            </button>
          )}
          <button className={styles.button} onClick={toggleDownloadPopup}>
            Lataa
            <img src={dowloadicon} alt="Icon" className={styles.icon} />
          </button>

          {/* "Share"-popup */}
          {isSharePopupOpen && (
            <div
              className={styles.popup}
              onClick={closeAll}
              onKeyDown={(e) => {
                if (e.key === 'Escape') closeAll();
              }}
              role="button"
              tabIndex={0}
            >
              <div
                className={styles.popupContent}
                onClick={(e) => e.stopPropagation()}
                onKeyDown={(e) => e.key === 'Escape' && e.stopPropagation()}
                role="button"
                tabIndex={0}
              >
                {!isEmbedFieldOpen && (
                  <div>
                    <h2 className={styles.shareheading}>Jaa tämä sisältö</h2>
                    <p>Valitse alusta, jolla haluat jakaa.</p>
                    <div className={styles.sharerow}>
                      <div
                        className={styles.share}
                        onClick={toggleEmbedField}
                        onKeyDown={(e) => {
                          if (e.key === 'Enter' || e.key === ' ')
                            toggleEmbedField();
                        }}
                        role="button"
                        tabIndex={0}
                      >
                        <div
                          className={`${styles.embed} ${styles.shareround}`}
                        ></div>
                        <p>Upota</p>
                      </div>

                      <div
                        className={styles.share}
                        onClick={shareToEmail}
                        onKeyDown={(e) => {
                          if (e.key === 'Enter' || e.key === ' ')
                            shareToEmail();
                        }}
                        role="button"
                        tabIndex={0}
                      >
                        <div
                          className={`${styles.email} ${styles.shareround}`}
                        ></div>
                        <p>Sähköposti</p>
                      </div>

                      <div
                        className={styles.share}
                        onClick={shareToWhatsApp}
                        onKeyDown={(e) => {
                          if (e.key === 'Enter' || e.key === ' ')
                            shareToWhatsApp();
                        }}
                        role="button"
                        tabIndex={0}
                      >
                        <div
                          className={`${styles.whatsapp} ${styles.shareround}`}
                        ></div>
                        <p>Whatsapp</p>
                      </div>

                      <div
                        className={styles.share}
                        onClick={shareToFacebook}
                        onKeyDown={(e) => {
                          if (e.key === 'Enter' || e.key === ' ')
                            shareToFacebook();
                        }}
                        role="button"
                        tabIndex={0}
                      >
                        <div
                          className={`${styles.facebook} ${styles.shareround}`}
                        ></div>
                        <p>Facebook</p>
                      </div>
                      <div
                        className={styles.share}
                        onClick={shareToTwitter}
                        onKeyDown={(e) => {
                          if (e.key === 'Enter' || e.key === ' ')
                            shareToTwitter();
                        }}
                        role="button"
                        tabIndex={0}
                      >
                        <div
                          className={`${styles.twitter} ${styles.shareround}`}
                        ></div>
                        <p>X</p>
                      </div>
                    </div>
                  </div>
                )}
                {/* Embed field*/}
                {isEmbedFieldOpen && (
                  <div>
                    <h3>Lisää upotuskoodi</h3>
                    <div className={styles.embedField}>
                      <textarea
                        placeholder="Syötä HTML upotuskoodi tähän..."
                        rows="5"
                        className={styles.textarea}
                        value={`<iframe
      src="https://moniviestin.fi/video/VIDEO_ID"
      width="100%"
      height="405"
      style="max-width: 720px; border: none; display: block; margin: 0 auto;"
      allowfullscreen></iframe>`}
                        readOnly
                        ref={textareaRef}
                      />
                    </div>

                    <div className={styles.embedfieldcopy}>
                      <button
                        className={styles.copyButton}
                        onClick={handleCopy}
                      >
                        Kopioi
                      </button>
                    </div>
                    {showToastEmbed && (
                      <div className={styles.toast}>Koodi kopioitu!</div>
                    )}
                  </div>
                )}

                {!isEmbedFieldOpen && (
                  <div>
                    <div className={styles.sharerow}>
                      <input
                        type="text"
                        value={currentUrl}
                        readOnly
                        ref={inputRef}
                        className={styles.urlField}
                      />

                      <button
                        onClick={copyToClipboard}
                        className={styles.copyButton}
                      >
                        Kopioi
                      </button>

                      {showToastUrl && (
                        <div className={styles.toast}>
                          Linkki kopioitu leikepöydälle!
                        </div>
                      )}
                    </div>
                    <div className={styles.checkboxcontainer}>
                      <label>
                        <input
                          type="checkbox"
                          checked={isChecked}
                          onChange={handleCheckboxChange}
                          className={styles.timelinkcheckbox}
                        />
                        Luo aikalinkki
                      </label>
                      {isChecked && (
                        <input
                          type="text"
                          placeholder="00:00"
                          value={timeValue}
                          onChange={handleTimeChange}
                          className={styles.timeinput}
                        />
                      )}
                    </div>
                  </div>
                )}

                <button
                  className={styles.closeButton}
                  onClick={closeAll}
                ></button>
              </div>
            </div>
          )}

          {/* Download popup */}
          {isDownloadPopupOpen && (
            <div
              className={`${styles.DownloadPopupClass} ${styles.popup}`}
              onClick={toggleDownloadPopup}
              onKeyDown={(e) => {
                if (e.key === 'Enter' || e.key === ' ') toggleDownloadPopup();
              }}
              role="button"
              tabIndex={0}
            >
              <div
                className={styles.popupContent}
                onClick={(e) => e.stopPropagation()}
                onKeyDown={(e) => {
                  if (e.key === 'Enter' || e.key === ' ') toggleDownloadPopup();
                }}
                role="button"
                tabIndex={0}
              >
                <h2>Lataa tiedosto</h2>
                <div className="downloads-container-heading">
                  <h2> Export </h2>
                </div>

                <div className="downloads-container-contents">
                  {showDisclaimer && (
                    <div className="downloads-container-text">
                      <Disclaimer />
                    </div>
                  )}
                  <div className="downloads-container-form">
                    <form>
                      {downloadItems.filter(
                        (item) => item['type'] === 'transcoding',
                      ).length > 0 && <h3> Media </h3>}
                      {downloadItems
                        .filter((item) => item['type'] === 'transcoding')
                        .sort(customSort)
                        .map((item, index) => (
                          <div key={item.id} style={{ marginBottom: '10px' }}>
                            <input
                              type="radio"
                              id={`downloadItem-${item.id}`}
                              name="downloadItem"
                              value={item.id}
                              checked={selectedItemId === item.id}
                              onChange={(e) =>
                                setSelectedItemId(e.target.value)
                              }
                              style={{ marginRight: '5px' }}
                            />
                            <label htmlFor={`downloadItem-${item.id}`}>
                              {item.title}
                            </label>
                          </div>
                        ))}
                      {downloadItems.filter(
                        (item) => item['type'] === 'subtitle',
                      ).length > 0 && <h3> Subtitles </h3>}
                      {downloadItems
                        .filter((item) => item['type'] === 'subtitle')
                        .sort(customSort)
                        .map((item, index) => (
                          <div key={item.id} style={{ marginBottom: '10px' }}>
                            <input
                              type="radio"
                              id={`downloadItem-${item.id}`}
                              name="downloadItem"
                              value={item.id}
                              checked={selectedItemId === item.id}
                              onChange={(e) =>
                                setSelectedItemId(e.target.value)
                              }
                              style={{ marginRight: '5px' }}
                            />
                            <label htmlFor={`downloadItem-${item.id}`}>
                              {item.title}
                            </label>
                          </div>
                        ))}

                      <Button
                        onClick={downloadButtonHandler}
                        style={{ marginTop: '10px' }}
                      >
                        Download
                      </Button>
                    </form>
                  </div>
                </div>
                <button
                  className={styles.closeButton}
                  onClick={toggleDownloadPopup}
                ></button>
              </div>
            </div>
          )}
        </div>
      </div>
    )
  );
};

export default Downloads;
