import { useState } from 'react';
import PlayIcon from './Icons/Play_arrow.svg';
import PauseIcon from './Icons/Pause.svg';

const PlayPauseButton = ({ className, audioRef }) => {
  const [isPlaying, setIsPlaying] = useState(false); // Tila: onko ääni käynnissä

  const handleToggle = () => {
    if (audioRef.current.paused) {
      audioRef.current.play();
      setIsPlaying(true);
    } else {
      audioRef.current.pause();
      setIsPlaying(false);
    }
  };

  return (
    <button className={className + ' Play-pause'} onClick={handleToggle}>
      <img
        className="Play-icon"
        src={isPlaying ? PauseIcon : PlayIcon}
        alt="Play/Pause"
      />
    </button>
  );
};

export default PlayPauseButton;
