import '@plone/volto/config';
import { DownloadsBlockView, DownloadsBlockEdit } from './components';
import {
  UploadButtonView,
  UploadButtonEdit,
} from 'volto-base-addon/components';

import { EPICS_TASK_VIEWS } from 'volto-epics-addon/constants';
import {
  TASK_UPLOAD_PROMPT,
  TASK_UPLOAD_BASICINFO_PROMPT,
  TASK_UPLOAD_WAITFORJOBS_VIEW,
  TASK_INTERMEDIATE_JOBS_VIEW,
  TASK_UPLOAD_DETAILS_PROMPT,
} from 'volto-base-addon/constants';
import { MEDIA_PAGE_WOWZA_ROUTE } from 'volto-base-addon/constants';

import heroSVG from '@plone/volto/icons/hero.svg';

const applyConfig = (config) => {
  config.blocks.blocksConfig.downloads = {
    id: 'downloads',
    title: 'Downloads',
    icon: heroSVG,
    group: 'media',
    view: DownloadsBlockView,
    edit: DownloadsBlockEdit,
    restricted: false,
    mostUsed: false,
    sidebarTab: 1,
  };

  config.blocks.blocksConfig.uploadbutton = {
    id: 'uploadbutton',
    title: 'Upload Button',
    icon: heroSVG,
    group: 'common',
    view: UploadButtonView,
    edit: UploadButtonEdit,
    restricted: false,
    mostUsed: false,
    sidebarTab: 1,
  };

  config.settings.nonContentRoutes = [
    ...config.settings.nonContentRoutes,
    '/file-upload',
    EPICS_TASK_VIEWS[TASK_UPLOAD_PROMPT],
    EPICS_TASK_VIEWS[TASK_UPLOAD_BASICINFO_PROMPT],
    EPICS_TASK_VIEWS[TASK_UPLOAD_WAITFORJOBS_VIEW],
    EPICS_TASK_VIEWS[TASK_INTERMEDIATE_JOBS_VIEW],
    EPICS_TASK_VIEWS[TASK_UPLOAD_DETAILS_PROMPT],
    new RegExp(`${config.settings.publicPath}/${MEDIA_PAGE_WOWZA_ROUTE}/.*`),
  ];
  return config;
};

export default applyConfig;
