import React, { useState, useEffect } from 'react';

const CurrentTimeDisplay = ({ audioRef }) => {
  const [currentTime, setCurrentTime] = useState(0);

  useEffect(() => {
    const audio = audioRef.current;
    if (!audio) return;

    const updateTime = () => setCurrentTime(audio.currentTime);
    audio.addEventListener('timeupdate', updateTime);

    return () => {
      audio.removeEventListener('timeupdate', updateTime);
    };
  }, [audioRef]);

  // Funktio muuntaa sekunnit muotoon mm:ss
  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  };

  return <span className="CurrentTime">{formatTime(currentTime)}</span>;
};

export default CurrentTimeDisplay;
