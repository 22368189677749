/**
 * VideoJSEdit block.
 * @module components/manage/Blocks/VideoJS/VideoJSEdit
 */

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { Message } from 'semantic-ui-react';
import cx from 'classnames';

import SidebarPortal from '@plone/volto/components/manage/Sidebar/SidebarPortal';
import videoBlockSVG from './videojs-icon.svg';
import Body from './Body';
import { withBlockExtensions } from '@plone/volto/helpers/Extensions';
import { compose } from 'redux';
import VideoJSSidebar from './VideoJSSidebar';
import { useVideoPlayerData } from './VideoPlayerDataHook';
import { v4 as uuid } from 'uuid';

/**
 * Edit videoJS block.
 * @component VideoJSEdit
 */
const VideoJSEdit = (props) => {
  const { block, data, onChangeBlock, properties } = props;
  const [key, setKey] = React.useState('');
  const [showPlayer, setShowPlayer] = useState(false);

  const playerData = useVideoPlayerData(
    properties.items,
    properties.s3_object_id,
    properties.live_stream_url,
    properties.UID,
  );

  useEffect(() => {
    if (Object.keys(playerData).length) {
      setShowPlayer(true);
      onChangeBlock(block, {
        ...data,
        options: {
          ...data.options,
          ...playerData,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [playerData]);

  const refreshPlayer = () => {
    setKey(uuid());
  };

  return (
    <div
      className={cx(
        'block video align',
        {
          selected: props.selected,
          center: !Boolean(props.data.align),
        },
        props.data.align,
      )}
    >
      {showPlayer &&
      props.data.options?.sources?.length &&
      props.data.options?.sources[0].src ? (
        <Body
          {...props}
          parentUid={properties?.UID}
          parentType={properties?.['@type']}
          parentItems={properties?.items}
          keyValue={key}
          editMode={true}
        />
      ) : (
        <Message>
          <center>
            <img src={videoBlockSVG} alt="" />
          </center>
        </Message>
      )}
      <SidebarPortal selected={props.selected}>
        <VideoJSSidebar {...props} refreshPlayer={refreshPlayer} />
      </SidebarPortal>
    </div>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
VideoJSEdit.propTypes = {
  selected: PropTypes.bool.isRequired,
  block: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  onChangeBlock: PropTypes.func.isRequired,
  onSelectBlock: PropTypes.func.isRequired,
  onDeleteBlock: PropTypes.func.isRequired,
  onFocusPreviousBlock: PropTypes.func.isRequired,
  onFocusNextBlock: PropTypes.func.isRequired,
  handleKeyDown: PropTypes.func.isRequired,
};

export default compose(injectIntl, withBlockExtensions)(VideoJSEdit);
