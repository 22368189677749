import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import jwtDecode from 'jwt-decode';
import BodyClass from '@plone/volto/helpers/BodyClass/BodyClass';
import { getUser } from '@plone/volto/actions/users/users';

const BodyInjection = () => {
  const [className, setClassName] = useState('');
  const dispatch = useDispatch();
  const { token } = useSelector((state) => state.userSession);
  const { user } = useSelector((state) => state.users);

  useEffect(() => {
    try {
      if (token) {
        let username = jwtDecode(token).sub;
        dispatch(getUser(username));
      }
    } catch (e) {}
  }, [token, dispatch]);

  useEffect(() => {
    try {
      const roles = user?.roles;
      if (roles) {
        const className = roles
          .map(
            (value) => `user-role-${value.toLowerCase().replace(/\s+/g, '-')}`,
          )
          .join(' ');
        setClassName(className);
      }
    } catch (e) {}
  }, [user]);

  return <BodyClass className={className} />;
};

export default BodyInjection;
