import { VideoJSView, VideoJSEdit } from '@package/components';
import { MediaPageVideoJSView } from '@package/components';
import GridCardListing from '@package/components/manage/Blocks/Listing/GridCardListing';
import MediaPageSummaryTemplate from '@package/components/manage/Blocks/Listing/MediaPageSummaryTemplate';
import RightColumnFacets from '@plone/volto/components/manage/Blocks/Search/layout/RightColumnFacets';
import LeftColumnFacets from '@plone/volto/components/manage/Blocks/Search/layout/LeftColumnFacets';
import { SelectFacetFilterListEntry } from '@plone/volto/components/manage/Blocks/Search/components';

import videoIcon from './icons/videojs.svg';

import '@plone/volto/config';

import { BodyInjection } from '@package/components';
import TreeFacet from '@package/components/manage/Blocks/Search/TreeFacet';

export default function applyConfig(config) {
  // VideoJS
  config.settings.subtitlesLanguageProperty = 'title';
  config.settings.subtitlesId = 's3_subtitles_id';
  config.settings.subtitlesCueInterval = 20;
  config.settings.remoteTextTrackIdPrefix = 'vjs_track_';
  config.settings.videoJSAllowedOptions = [
    'controls',
    'fluid',
    'height',
    'playbackRates',
    'responsive',
    'sources',
    'poster',
    'audioOnlyMode',
    'autoplay',
    'width',
    'loop',
    'liveui',
    'muted',
    'watermark',
    'subtitles',
    'timeRange',
  ];

  config.settings.defaultPageSize = 20;
  config.settings.isMultilingual = false;
  config.settings.supportedLanguages = ['fi'];
  config.settings.defaultLanguage = 'fi';
  config.settings.publicPath = '';

  // volto-light-theme setting
  config.settings.enableFatMenu = false;

  config.blocks.blocksConfig.videojs = {
    id: 'videojs',
    title: 'VideoJS',
    group: 'common',
    icon: videoIcon,
    view: VideoJSView,
    edit: VideoJSEdit,
    restricted: false,
    mostUsed: true,
    sidebarTab: 1,
    security: {
      addPermission: [],
      view: [],
    },
  };

  config.blocks.blocksConfig.mediapagevideojs = {
    id: 'mediapagevideojs',
    title: 'Media Page VideoJS',
    group: 'common',
    icon: videoIcon,
    view: MediaPageVideoJSView,
    edit: VideoJSEdit,
    restricted: false,
    mostUsed: false,
    sidebarTab: 1,
    blockHasOwnFocusManagement: true,
    security: {
      addPermission: [],
      view: [],
    },
  };

  config.blocks.blocksConfig.gridBlock.allowedBlocks = [
    'image',
    'listing',
    'slate',
    'teaser',
    'videojs',
  ];

  config.blocks.blocksConfig.listing.variations = [
    {
      id: 'mediapagesummary',
      title: 'MediaPageSummary',
      template: MediaPageSummaryTemplate,
      fullobjects: true,
    },
    {
      id: 'gridcardlisting',
      title: 'Grid Card Listing',
      template: GridCardListing,
      fullobjects: true,
    },
    ...config.blocks.blocksConfig.listing.variations,
  ];

  // Extend listing block with a hide property
  config.blocks.blocksConfig.listing.schemaEnhancer = ({
    schema,
    formData,
    intl,
  }) => {
    schema.properties.hideIfEmpty = {
      title: 'Hide if empty',
      type: 'boolean',
      default: false,
      description:
        'If checked, the block will not render when there are no items to display.',
    };
    schema.fieldsets[0].fields.push('hideIfEmpty');

    return schema;
  };

  config.blocks.blocksConfig.search = {
    ...config.blocks.blocksConfig.search,
    variations: [
      ...config.blocks.blocksConfig.search.variations,
      {
        id: 'facetsRightSide',
        title: 'Facets on right',
        view: RightColumnFacets,
        isDefault: true,
      },
      {
        id: 'facetsLeftSide',
        title: 'Facets on left',
        view: LeftColumnFacets,
        isDefault: false,
      },
    ],
    extensions: {
      ...config.blocks.blocksConfig.search.extensions,
      facetWidgets: {
        ...config.blocks.blocksConfig.search.extensions.facetWidgets,
        types: [
          ...config.blocks.blocksConfig.search.extensions.facetWidgets.types,
          {
            id: 'treeFacet',
            title: 'Tree',
            view: TreeFacet,
            isDefault: false,
            stateToValue: TreeFacet.stateToValue,
            valueToQuery: TreeFacet.valueToQuery,
            filterListComponent: SelectFacetFilterListEntry,
          },
        ],
      },
    },
  };

  // Hide some blocks from UI
  const hiddenBlocks = [
    'videojs',
    'mediapagevideojs',
    'downloads',
    'uploadbutton',
    'video',
  ];
  Object.keys(config.blocks.blocksConfig).forEach((blockId, idx) => {
    const blockConfig = config.blocks.blocksConfig[blockId];
    const originalRestricted = blockConfig.restricted;
    blockConfig.restricted = (props) => {
      if (hiddenBlocks.includes(blockId)) {
        return true;
      }
      return originalRestricted;
    };
  });

  config.settings.appExtras = [
    ...config.settings.appExtras,
    {
      match: '',
      component: BodyInjection,
    },
  ];

  if (__SERVER__) {
    const myMiddleware = require('@package/middleware');
    config.settings.expressMiddleware.push(myMiddleware.default(config));
  }

  return config;
}
