import React, { useEffect, useRef, useState, useCallback } from 'react';
import {
  Form,
  Header,
  Container,
  Button,
  Checkbox,
  Segment,
} from 'semantic-ui-react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { EPICS_PATH_CONTINUE } from 'volto-epics-addon/constants';
import { TASK_UPLOAD_DETAILS_PROMPT } from 'volto-base-addon/constants';
import { completeTask, useTask } from 'volto-epics-addon/helpers';
import classnames from 'classnames';
import moment from 'moment';
import Body from '@package/components/manage/Blocks/VideoJS/Body';
import { MEDIA_PAGE_WOWZA_ROUTE } from 'volto-base-addon/constants';
import config from '@plone/volto/registry';
import { injectLazyLibs } from '@plone/volto/helpers/Loadable/Loadable';
import SelectWidget from '@plone/volto/components/theme/Widgets/SelectWidget';
import { LANGUAGES_LIST } from '@package/constants/ISO639-1';
import { ACCEPTED_LANGUAGE_CODES } from '@package/constants/whisperxAcceptedLanguages';
import UploadStepsContainer from '@package/components/theme/FileUpload/UploadStepsContainer';

const Page = () => {
  const history = useHistory();
  const task = useTask(TASK_UPLOAD_DETAILS_PROMPT);
  const duration = parseFloat(task?.variables?.duration); // in microseconds
  const availableProfiles = task?.variables?.fileInfo?.['details'] ?? {};
  const token = useSelector((state) => state.userSession.token);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [filters, setFilters] = useState({
    dynaudnorm: false,
    denoiser: false,
    speechnorm: false,
  });
  const [subtitles, setSubtitles] = useState({
    transcription: false,
    diarization: false,
    translate: false,
  });
  const [doTrim, setDoTrim] = useState(false);
  const [doFaceblur, setDoFaceblur] = useState(false);
  const [start, setStart] = useState(0);
  const [end, setEnd] = useState(0);
  const [startVal, setStartVal] = useState(0);
  const [endVal, setEndVal] = useState(0);
  const [previewSrc, setPreviewSrc] = useState([{ src: '', type: '' }]);
  const [timeRange, setTimeRange] = useState({ min: startVal, max: endVal });
  const startValRef = useRef(null);
  const endValRef = useRef(null);
  const range = useRef(null);
  const rangeLeftValue = useRef(null);
  const rangeRightValue = useRef(null);
  const framesetPresignedUrl = task?.variables?.framesetPresignedUrl;
  const operationsPreview = task?.variables?.operationsPreview;
  const s3_object_id = task?.variables?.smilFilename;
  const mediaPageUid = task?.variables?.mediaPageUid;

  useEffect(() => {
    setIsAuthenticated(!!token);
    setIsVisible(true);
  }, [token]);

  useEffect(() => {
    let controller = undefined;
    if (task?.completed === true) {
      history.push(EPICS_PATH_CONTINUE);
    } else {
      controller = new AbortController();

      s3_object_id &&
        (async () => {
          const resp = await fetch(
            `${config.settings.publicPath}/${MEDIA_PAGE_WOWZA_ROUTE}/${mediaPageUid}/${s3_object_id}`,
            {
              signal: controller.signal,
            },
          );

          const jsonData = await resp.json();

          if (jsonData['url']) {
            setPreviewSrc([
              {
                src: jsonData['url'],
                type: false,
              },
            ]);
          }
        })();
    }
    return () => {
      controller?.abort();
    };
  }, [task, history, mediaPageUid, s3_object_id]);

  const onSubmit = async (ev) => {
    if (!token) return;

    ev.nativeEvent.preventDefault();

    try {
      completeTask(TASK_UPLOAD_DETAILS_PROMPT, {
        filters:
          'audio' in availableProfiles && availableProfiles['audio'].length > 0
            ? JSON.stringify(filters)
            : JSON.stringify({
                dynaudnorm: false,
                denoiser: false,
                speechnorm: false,
              }),
        faceblur:
          'video' in availableProfiles && availableProfiles['video'].length > 0
            ? JSON.stringify(doFaceblur)
            : JSON.stringify(false),
        trim: doTrim
          ? JSON.stringify({
              start: startVal,
              end: endVal,
            })
          : JSON.stringify({
              start: start,
              end: end,
            }),
        subtitles:
          'audio' in availableProfiles && availableProfiles['audio'].length > 0
            ? JSON.stringify(subtitles)
            : JSON.stringify({
                transcription: false,
                diarization: false,
                language: null,
              }),
      });
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };

  // Convert to percentage
  const getPercent = useCallback(
    (value) => Math.round(((value - start) / (end - start)) * 100),
    [start, end],
  );

  useEffect(() => {
    if (duration) {
      setStart(0);
      setEnd(duration / 1e6);
      setStartVal(0);
      setEndVal(duration / 1e6);
    }
  }, [duration]);

  // Set width of the range to decrease from the left side
  useEffect(() => {
    if (endValRef.current) {
      const startPercent = getPercent(startVal);
      const endPercent = getPercent(+endValRef.current.value);
      const width = endPercent - startPercent;
      const leftRangeStyle =
        startPercent < 10 ? startPercent : startPercent - 6;

      if (range.current) {
        range.current.style.left = `${startPercent}%`;
        range.current.style.width = `${width}%`;

        rangeLeftValue.current.style.left = `${leftRangeStyle}%`;

        if (endPercent > 90 && width <= 6) {
          rangeRightValue.current.style.right = `${94 - endPercent + width}%`;
        }
      }

      setTimeRange((prevState) => ({
        ...prevState,
        min: startVal,
      }));
    }
  }, [startVal, getPercent, doTrim]);

  // Set width of the range to decrease from the right side
  useEffect(() => {
    if (startValRef.current) {
      const startPercent = getPercent(+startValRef.current.value);
      const endPercent = getPercent(endVal);
      const width = endPercent - startPercent;
      const rightRangeStyle =
        endPercent > 90 ? 100 - endPercent : 94 - endPercent;

      if (range.current) {
        range.current.style.width = `${width}%`;
        rangeRightValue.current.style.right = `${rightRangeStyle}%`;

        if (startPercent < 10 && width <= 6) {
          rangeLeftValue.current.style.left = `${width - 6}%`;
        }
      }

      setTimeRange((prevState) => ({
        ...prevState,
        max: endVal,
      }));
    }
  }, [endVal, getPercent, doTrim]);

  return isVisible ? (
    isAuthenticated ? (
      <Container className="details-prompt-container">
        <UploadStepsContainer currentStep={3} totalSteps={4} />
        <Header> Details </Header>
        <Form onSubmit={onSubmit}>
          {'video' in availableProfiles &&
            availableProfiles['video'].length > 0 && (
              <>
                <Form.Group grouped className="details-prompt-faceblur">
                  <h4>Anonymization</h4>
                  <Form.Field
                    onChange={(e, data) => setDoFaceblur(data.checked)}
                    label="Automatic face blurring"
                    control={Checkbox}
                  ></Form.Field>
                </Form.Group>
              </>
            )}
          {'audio' in availableProfiles &&
            availableProfiles['audio'].length > 0 && (
              <>
                <Form.Group grouped className="details-prompt-filters">
                  <h4>Filters</h4>
                  <Form.Field
                    onChange={(e, data) =>
                      setFilters((prevState) => ({
                        ...prevState,
                        dynaudnorm: data.checked,
                      }))
                    }
                    label="Dynamic Audio Normalizer"
                    control={Checkbox}
                  ></Form.Field>
                  <Form.Field
                    onChange={(e, data) =>
                      setFilters((prevState) => ({
                        ...prevState,
                        denoiser: data.checked,
                      }))
                    }
                    label="Broadband Noise Reducer"
                    control={Checkbox}
                  ></Form.Field>
                  <Form.Field
                    onChange={(e, data) =>
                      setFilters((prevState) => ({
                        ...prevState,
                        speechnorm: data.checked,
                      }))
                    }
                    label="Speech Normalizer"
                    control={Checkbox}
                  ></Form.Field>
                </Form.Group>
                <Form.Group grouped className="details-prompt-subtitles">
                  <h4>Subtitles</h4>
                  <Form.Field
                    onChange={(e, data) =>
                      setSubtitles((prevState) => ({
                        ...prevState,
                        transcription: data.checked,
                      }))
                    }
                    label="Generate subtitles"
                    control={Checkbox}
                  ></Form.Field>
                  {subtitles.transcription && (
                    <>
                      <SelectWidget
                        className="transcription-language-select"
                        id="transcription-language"
                        title="Speech language"
                        description="By choosing a language you improve accuracy and pricision of final result"
                        choices={Object.keys(LANGUAGES_LIST)
                          .filter((key) =>
                            ACCEPTED_LANGUAGE_CODES.includes(key),
                          )
                          .map((key) => [key, LANGUAGES_LIST[key]['name']])}
                        onChange={(id, value) =>
                          setSubtitles((prevState) => ({
                            ...prevState,
                            language: value,
                          }))
                        }
                        value={subtitles['language'] || ''}
                      />
                      <Form.Field
                        className="diarization"
                        onChange={(e, data) =>
                          setSubtitles((prevState) => ({
                            ...prevState,
                            diarization: data.checked,
                          }))
                        }
                        label="Speaker diarization"
                        control={Checkbox}
                      ></Form.Field>
                      <Form.Field
                        className="transcription-translate"
                        onChange={(e, data) =>
                          setSubtitles((prevState) => ({
                            ...prevState,
                            translate: data.checked,
                          }))
                        }
                        label="Translate (to English)"
                        control={Checkbox}
                      ></Form.Field>
                    </>
                  )}
                </Form.Group>
              </>
            )}
          <Form.Group grouped className="details-prompt-range">
            <h4>Video Length</h4>
            <Form.Field
              onChange={(e, data) => setDoTrim(data.checked)}
              label="Trim media"
              control={Checkbox}
            ></Form.Field>
            {doTrim ? (
              <div className="range-preview">
                {previewSrc && previewSrc[0].src && (
                  <Body
                    data={{
                      options: {
                        audioOnlyMode:
                          operationsPreview?.profiles?.length === 1 &&
                          operationsPreview.profiles[0] === 'Audio',
                        sources: previewSrc,
                        timeRange: timeRange,
                        controls: true,
                        height: 400,
                        responsive: true,
                        playbackRates: [0.5, 0.75, 1, 1.25, 1.5, 2],
                        fluid: true,
                      },
                    }}
                    editMode={false}
                  />
                )}
                <Segment className="range-segment">
                  <span
                    style={{
                      color: '#68778D',
                      fontSize: '0.9em',
                    }}
                  >
                    A media trimmer allows you to reduce the length of your
                    media <b>(video preview resolution is 360p)</b>
                  </span>
                  <div
                    className="range-slider-container"
                    style={{ backgroundImage: `url(${framesetPresignedUrl})` }}
                  >
                    <input
                      name={`field-slider-min`}
                      type="range"
                      min={start}
                      max={end}
                      value={startVal}
                      ref={startValRef}
                      onChange={(event) => {
                        const value = Math.min(+event.target.value, endVal - 1);
                        setStartVal(value);
                        event.target.value = value.toString();
                      }}
                      className={classnames('thumb thumb-min', {
                        'thumb-zindex-5': startVal > end - 100,
                      })}
                    />
                    <input
                      name={`field-slider-max`}
                      type="range"
                      min={start}
                      max={end}
                      value={endVal}
                      ref={endValRef}
                      onChange={(event) => {
                        const value = Math.max(
                          +event.target.value,
                          startVal + 1,
                        );
                        setEndVal(value);
                        event.target.value = value.toString();
                      }}
                      className="thumb thumb-max"
                    />
                    <div className="range-slider">
                      <div className="range-slider-track" />
                      <div ref={range} className="range-slider-range" />
                      <div
                        ref={rangeLeftValue}
                        className="range-slider-left-value"
                      >
                        {moment
                          .utc(
                            moment
                              .duration(startVal, 'seconds')
                              .asMilliseconds(),
                          )
                          .format('HH')}
                        :
                        {moment
                          .utc(
                            moment
                              .duration(startVal, 'seconds')
                              .asMilliseconds(),
                          )
                          .format('mm')}
                        :
                        {moment
                          .utc(
                            moment
                              .duration(startVal, 'seconds')
                              .asMilliseconds(),
                          )
                          .format('ss')}
                      </div>
                      <div
                        ref={rangeRightValue}
                        className="range-slider-right-value"
                      >
                        {moment
                          .utc(
                            moment.duration(endVal, 'seconds').asMilliseconds(),
                          )
                          .format('HH')}
                        :
                        {moment
                          .utc(
                            moment.duration(endVal, 'seconds').asMilliseconds(),
                          )
                          .format('mm')}
                        :
                        {moment
                          .utc(
                            moment.duration(endVal, 'seconds').asMilliseconds(),
                          )
                          .format('ss')}
                      </div>
                    </div>
                  </div>
                </Segment>
              </div>
            ) : null}
          </Form.Group>
          <Button style={{ marginTop: '1em' }} type="submit" floated="right">
            Continue
          </Button>
        </Form>
      </Container>
    ) : (
      <Container className="details-prompt-container">
        <Header> You must log in first. </Header>
      </Container>
    )
  ) : (
    <Container className="details-prompt-container"></Container>
  );
};

export default injectLazyLibs('reactSelect')(Page);
