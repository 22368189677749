import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Api from '@plone/volto/helpers/Api/Api';
import mime from 'mime-types';
import config from '@plone/volto/registry';
import { MEDIA_PAGE_WOWZA_ROUTE } from 'volto-base-addon/constants';
import { flattenToAppURL } from '@plone/volto/helpers/Url/Url';

const subtitlesFromItems = async (items, token, api) => {
  const subtitles = [];
  for (const item of items) {
    if (item['@type'] === 'subtitle') {
      // Only take subtitles marked as completed
      const workflow = await api.get(
        flattenToAppURL(item['@id']) + '/@workflow',
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
      const state =
        workflow?.['chain']?.['media_output_workflow']?.['state']?.['id'];
      if (state && state !== 'completed') {
        continue;
      }
      subtitles.push([
        {
          '@id': item['url'],
          title: item['title'],
          language: item['title'],
        },
      ]);
    }
  }
  return subtitles;
};

export const useVideoPlayerData = (
  items,
  objectId,
  live_stream_url,
  uid,
  onFail = null,
) => {
  const { token } = useSelector((state) => state.userSession);
  const api = new Api();
  const [data, setData] = useState({});

  useEffect(() => {
    let controller = new AbortController();
    let isMounted = true;

    (async () => {
      const tempData = {};
      if (items.length) {
        const subtitles = await subtitlesFromItems(items, token, api);

        if (subtitles.length) {
          tempData.subtitles = subtitles;
        }
      }

      if (objectId !== null && objectId !== '') {
        try {
          const resp = await fetch(
            `${config.settings.publicPath}/${MEDIA_PAGE_WOWZA_ROUTE}/${uid}/${objectId}`,
            {
              signal: controller.signal,
            },
          );
          const jsonData = await resp.json();
          if (jsonData['url']) {
            tempData.sources = [
              {
                src: jsonData['url'],
                type: mime.lookup(jsonData['url']),
              },
            ];
          }
        } catch (e) {
          if (onFail) {
            onFail(e);
          }
          // eslint-disable-next-line no-console
          console.log(e);
        }
      } else {
        if (live_stream_url) {
          tempData.sources = [
            {
              src: live_stream_url,
              type: mime.lookup(live_stream_url),
            },
          ];
        }
      }
      if (isMounted) {
        setData(tempData);
      }
    })();
    return () => {
      controller?.abort();
      isMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return data;
};
