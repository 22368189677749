import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ConditionalLink from '@plone/volto/components/manage/ConditionalLink/ConditionalLink';
import { flattenToAppURL } from '@plone/volto/helpers/Url/Url';
import { MEDIA_PAGE_PREVIEW_ROUTE } from 'volto-base-addon/constants';
import { isInternalURL } from '@plone/volto/helpers/Url/Url';
import request from 'superagent';
import config from '@plone/volto/registry';
import { Loader } from 'semantic-ui-react';
import DefaultImageSVG from '@plone/volto/components/manage/Blocks/Listing/default-image.svg';
import LivePreviewSVG from './preview_live.svg';
import AudioPreviewSVG from './preview_audio.svg';
import FilePreviewSVG from './preview_file.svg';
import { useIntl, defineMessages } from 'react-intl';

const messages = defineMessages({
  loading: {
    id: 'loading',
    defaultMessage: 'Loading...',
  },
});

const Item = ({ item, images }) => {
  let { image, hoverImage } = images;
  if (hoverImage === null) {
    hoverImage = image;
  }
  const [previewOn, setPreviewOn] = useState(false);
  return (
    <div
      className="mediapage-item"
      onMouseEnter={() => {
        setPreviewOn(true);
      }}
      onMouseLeave={() => {
        setPreviewOn(false);
      }}
    >
      <div className="mediapage-img-wrapper">
        <img
          className="mediapage-preview-img"
          src={!previewOn ? image : hoverImage}
          alt={`${item.title} preview`}
        ></img>
      </div>
      <div className="listing-body">
        <h3>{item.title || item.id}</h3>
        <p>{item.description}</p>
      </div>
    </div>
  );
};

const MediaPageSummaryTemplate = ({
  items,
  linkTitle,
  linkHref,
  isEditMode,
}) => {
  let link = null;
  let href = linkHref?.[0]?.['@id'] || '';
  const [ready, setReady] = useState(false);
  const intl = useIntl();

  if (isInternalURL(href)) {
    link = (
      <ConditionalLink to={flattenToAppURL(href)} condition={!isEditMode}>
        {linkTitle || href}
      </ConditionalLink>
    );
  } else if (href) {
    link = <a href={href}>{linkTitle || href}</a>;
  }

  useEffect(() => {
    let isMounted = true;
    if (!ready) {
      (async () => {
        for (const item of items) {
          if (
            item['@type'] === 'media_page' &&
            item.hasOwnProperty('s3_image_preview') &&
            item.hasOwnProperty('s3_video_preview')
          ) {
            for (const value of Object.values(item.blocks)) {
              if (value['@type'] === 'mediapagevideojs' && value['poster']) {
                item['poster'] = value['poster'];
              }
            }
            try {
              if (
                item.s3_image_preview &&
                !item.s3_image_preview.includes('http')
              ) {
                const { body: imagePreview } = await request.get(
                  `${config.settings.publicPath}/${MEDIA_PAGE_PREVIEW_ROUTE}/${item.UID}/${item.s3_image_preview}`,
                );
                item.s3_image_preview = imagePreview['presigned_url'];
              }
              if (
                item.s3_video_preview &&
                !item.s3_video_preview.includes('http')
              ) {
                const { body: videoPreview } = await request.get(
                  `${config.settings.publicPath}/${MEDIA_PAGE_PREVIEW_ROUTE}/${item.UID}/${item.s3_video_preview}`,
                );
                item.s3_video_preview = videoPreview['presigned_url'];
              }
            } catch (e) {
              // eslint-disable-next-line no-console
              console.log(e);
            }
          }
        }
        if (isMounted) {
          setReady(true);
        }
      })();
    }
    return () => {
      isMounted = false;
    };
  });

  const getPreviewImages = (item) => {
    if (item['poster']) {
      return {
        image: `data:${item.poster['content-type']};base64,${item.poster['data']}`,
        hoverImage: null,
      };
    }
    if (item['image']) {
      let image = item['image']['scales']['preview']
        ? item['image']['scales']['preview']['download']
        : item['image']['download'];
      return {
        image: image,
        hoverImage: null,
      };
    }

    if (item['@type'] === 'media_page') {
      // Only image preview
      if (item.s3_image_preview && !item.s3_video_preview) {
        return {
          image: item.s3_image_preview,
          hoverImage: null,
        };
      }
      // Only video preview
      if (!item.s3_image_preview && item.s3_video_preview) {
        return {
          image: DefaultImageSVG,
          hoverImage: item.s3_video_preview,
        };
      }
      // Neither image nor video
      if (!item.s3_image_preview && !item.s3_video_preview) {
        let image = !item['s3_object_id'] ? LivePreviewSVG : AudioPreviewSVG;
        return {
          image: image,
          hoverImage: null,
        };
      }
      // Both image and video
      if (item.s3_image_preview && item.s3_video_preview) {
        return {
          image: item.s3_image_preview,
          hoverImage: item.s3_video_preview,
        };
      }
    }

    if (item['@type'] === 'File') {
      return {
        image: FilePreviewSVG,
        hoverImage: null,
      };
    }
    return {
      image: DefaultImageSVG,
      hoverImage: null,
    };
  };

  return (
    <>
      <div className="items mediapage-summary-template">
        {ready ? (
          items.map((item) => (
            <div className="listing-item" key={item['@id']}>
              <ConditionalLink item={item} condition={!isEditMode}>
                <Item item={item} images={getPreviewImages(item)} />
              </ConditionalLink>
            </div>
          ))
        ) : (
          <>
            <p>{intl.formatMessage(messages.loading)}</p>
            <Loader active inline />
          </>
        )}
      </div>

      {link && <div className="footer">{link}</div>}
    </>
  );
};

MediaPageSummaryTemplate.propTypes = {
  items: PropTypes.arrayOf(PropTypes.any).isRequired,
  linkMore: PropTypes.any,
  isEditMode: PropTypes.bool,
};

export default MediaPageSummaryTemplate;
