import { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import Api from '@plone/volto/helpers/Api/Api';

// Custom hook for workflow state polling
export const useWorkflowStatePolling = (
  contentItems,
  keyFromItem,
  pollInterval = 15000,
  allowedTypes = ['subtitle', 'transcoding'],
) => {
  const { token } = useSelector((state) => state.userSession);
  const api = new Api();

  const [workflowState, setWorkflowState] = useState({});
  const itemsRef = useRef(contentItems);
  const workflowStateRef = useRef(workflowState);

  // Function to get the workflow state
  const getWorkflowState = async (output_id, token) => {
    try {
      const workflow = await api.get(
        new URL(output_id).pathname + '/@workflow',
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
      return workflow?.['chain']?.['media_output_workflow']?.['state']?.['id'];
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error fetching or processing workflow:', error);
    }
  };

  // Function to check and update the workflow states
  const checkAndUpdateWorkflowStates = async () => {
    let changesMade = false;
    const newState = { ...workflowStateRef.current };

    const inProgressItems = itemsRef.current.filter((item) => {
      if (!allowedTypes.includes(item['@type'])) {
        return false;
      }
      const key = keyFromItem(item);
      if (key === null) {
        return false;
      }
      const isInProgress = newState[key]?.state === 'in_progress';
      return !newState[key] || isInProgress;
    });

    if (inProgressItems.length === 0) return;

    for (const item of inProgressItems) {
      const key = keyFromItem(item);
      const currentState = await getWorkflowState(item['@id'], token);
      if (currentState !== newState[key]?.state) {
        newState[key] = { state: currentState };
        changesMade = true;
      }
    }
    if (changesMade) {
      setWorkflowState(newState);
    }
  };

  // Effect to update the ref whenever contentItems changes
  useEffect(() => {
    itemsRef.current = contentItems;
  }, [contentItems]);

  // Effect to update the ref whenever workflowState changes
  useEffect(() => {
    workflowStateRef.current = workflowState;
  }, [workflowState]);

  // Effect to start polling the workflow state
  useEffect(() => {
    checkAndUpdateWorkflowStates();

    const interval = setInterval(() => {
      checkAndUpdateWorkflowStates();
    }, pollInterval);

    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pollInterval, token]);

  return workflowState;
};
