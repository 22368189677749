import { useState, useEffect } from 'react';

export const useLiveActivePoll = (
  liveStreamUrl,
  shouldPoll,
  pollIntervalMilliseconds,
  onActive,
) => {
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    let controller = new AbortController();
    let timer;
    let isMounted = true;

    if (shouldPoll && liveStreamUrl) {
      const handler = async () => {
        try {
          const response = await fetch(liveStreamUrl, {
            method: 'HEAD',
            signal: controller.signal,
          });
          if (!response.ok) {
            throw new Error(`Response status: ${response.status}`);
          }
          if (isMounted) {
            setIsActive(true);
            onActive();
          }
        } catch (error) {
          if (isMounted) {
            setIsActive(false);
          }
        }
      };
      handler();
      timer = setInterval(handler, pollIntervalMilliseconds);
    }

    return () => {
      isMounted = false;
      if (timer) {
        clearInterval(timer);
      }
      controller?.abort();
    };
  }, [liveStreamUrl, shouldPoll, pollIntervalMilliseconds, onActive]);

  return isActive;
};
