import { useState } from 'react';
import VolumeIcon from './Icons/Volume.svg';

const VolumeControl = ({ audioRef }) => {
  const [showSlider, setShowSlider] = useState(false);

  const handleVolumeChange = (event) => {
    audioRef.current.volume = event.target.value;
  };

  return (
    <div
      className={`VolumeControlContainer ${showSlider ? 'active' : ''}`}
      style={{ position: 'relative', display: 'inline-block' }}
    >
      <button
        className="VolumeControl"
        onClick={() => setShowSlider(!showSlider)}
        style={{
          background: 'none',
          border: 'none',
          cursor: 'pointer',
          fontSize: '20px',
          padding: '5px',
        }}
      >
        <img className="VolumeIcon" src={VolumeIcon} alt="Play/Pause" />
      </button>

      {showSlider && (
        <input
          type="range"
          min="0"
          max="1"
          step="0.1"
          onChange={handleVolumeChange}
          className="VolumeSlider"
        />
      )}
    </div>
  );
};

export default VolumeControl;
