const VideoPlaceholder = (props) => {
  const { children } = props;

  return (
    <div className="video-placeholder">
      <div className="video-placeholder-content">{children}</div>
    </div>
  );
};

export default VideoPlaceholder;
