import { useEffect, useState, useCallback, useRef } from 'react';
import { useSelector } from 'react-redux';
import Body from '../Blocks/VideoJS/Body';
import Api from '@plone/volto/helpers/Api/Api';
import { MEDIA_PAGE_WOWZA_ROUTE } from 'volto-base-addon/constants';
import { flattenToAppURL } from '@plone/volto/helpers/Url/Url';
import config from '@plone/volto/registry';
import mime from 'mime-types';
import PlayPauseButton from './PlayPauseButton';
import SeekBar from './SeekBar';
import SpeedControl from './SpeedControl';
import VolumeControl from './VolumeControl';
import CurrentTimeDisplay from './CurrentTimeDisplay';
import AudioDurationDisplay from './AudioDurationDisplay';
import PlayIcon from './Icons/Play_arrow.svg';

const Embed = () => {
  const { token } = useSelector((state) => state.userSession);
  const [audioSrc, setAudioSrc] = useState(null);
  const [videoPlayerData, setVideoPlayerData] = useState(null);
  const [videoBodyProps, setVideoBodyProps] = useState(null);
  const [videoTitle, setVideoTitle] = useState('');
  const [videoDescription, setVideoDescription] = useState('');
  const [videoImage, setVideoImage] = useState('');
  const [isMobilePlayerOpen, setIsMobilePlayerOpen] = useState(false);
  const audioRef = useRef(null);

  const handleOpenPlayer = () => {
    setIsMobilePlayerOpen(true);
  };

  /**
   * Hakee media-sivun tiedot UID:n perusteella
   */
  const fetchMediaPageByUid = useCallback(
    async (uid) => {
      const api = new Api();
      try {
        const response = await api.get('/@search', {
          params: { UID: uid, fullobjects: true },
          headers: { Authorization: `Bearer ${token}` },
        });
        return response?.items?.length === 1 ? response.items[0] : null;
      } catch (error) {
        console.error('Error fetching main object:', error);
        return null;
      }
    },
    [token],
  );

  /**
   * Hakee kaikki media-sivun alielementit
   */
  const fetchChildren = useCallback(
    async (path) => {
      const api = new Api();
      try {
        const response = await api.get(`${path}/@search`, {
          params: { fullobjects: true },
          headers: { Authorization: `Bearer ${token}` },
        });
        return response?.items || [];
      } catch (error) {
        console.error('Error fetching children:', error);
        return [];
      }
    },
    [token],
  );

  /**
   * Hakee ja luo blob-URL:n annetulle tiedostolle
   */
  const fetchAndCreateBlobUrl = useCallback(async (url) => {
    try {
      const response = await fetch(url);
      if (!response.ok) throw new Error('Network response was not ok');
      const blob = await response.blob();
      return URL.createObjectURL(blob);
    } catch (error) {
      console.error('Error fetching audio data:', error);
      return null;
    }
  }, []);

  /**
   * Hakee mahdolliset tekstitykset
   */
  const fetchSubtitles = useCallback(
    async (children) => {
      const api = new Api();
      const subtitles = [];

      for (const item of children) {
        if (item['@type'] === 'subtitle') {
          const workflow = await api.get(
            flattenToAppURL(item['@id']) + '/@workflow',
            {
              headers: { Authorization: `Bearer ${token}` },
            },
          );

          const state =
            workflow?.['chain']?.['media_output_workflow']?.['state']?.['id'];
          if (state && state !== 'completed') continue;

          subtitles.push({
            '@id': new URL(item['@id']).pathname,
            title: item['title'],
            language: item['title'],
          });
        }
      }
      return subtitles;
    },
    [token],
  );

  /**
   * Parsii video blockin datan
   */
  const parseBlockData = (mediaPage) => {
    if (!mediaPage || !mediaPage.blocks) {
      console.warn('No blocks found in mediaPage');
      return null;
    }
    return (
      Object.values(mediaPage.blocks).find(
        (block) => block['@type'] === 'mediapagevideojs',
      ) || null
    );
  };

  /**
   * Hakee ja asettaa video/audio-soittimen tiedot
   */
  useEffect(() => {
    const updatePlayerData = async () => {
      const searchParams = new URLSearchParams(window.location.search);
      const uid = searchParams.get('uid');
      const type = searchParams.get('type') || 'video';
      const api = new Api();

      if (!uid) {
        console.error('UID not provided!');
        return;
      }

      const mediaPage = await fetchMediaPageByUid(uid);
      if (!mediaPage || mediaPage['@type'] !== 'media_page') {
        console.error('Media Page not found!');
        return;
      }

      setVideoTitle(mediaPage.title);
      setVideoDescription(mediaPage.description);
      setVideoImage(mediaPage.image);

      const path = new URL(mediaPage['@id']).pathname;
      const children = await fetchChildren(path);

      if (type === 'audio') {
        const transcoding = children.find(
          (child) =>
            new URL(child['@id']).pathname.split('/').slice(-1)[0] === 'audio',
        );

        if (!transcoding) {
          console.error('Audio content not found!');
          return;
        }

        const url = flattenToAppURL(mediaPage['@id']) + '/@download-presigned';
        const resp = await api.post(url, {
          data: {
            s3_object_id: transcoding['s3_object_id'],
            s3_object_path: uid,
          },
          headers: { Authorization: `Bearer ${token}` },
        });

        const blobUrl = await fetchAndCreateBlobUrl(resp.presigned_url);
        setAudioSrc(blobUrl);
      } else {
        const subtitles = await fetchSubtitles(children);
        const blockData = parseBlockData(mediaPage);

        if (!blockData) {
          console.error('No valid video block found!');
          return;
        }

        const resp = await fetch(
          `${config.settings.publicPath}/${MEDIA_PAGE_WOWZA_ROUTE}/${uid}/${mediaPage.s3_object_id}`,
        );

        if (!resp.ok) {
          console.error('Failed to fetch video stream URL');
          return;
        }

        const jsonData = await resp.json();
        const url = jsonData['url'];

        const mediaData = {
          sources: [{ src: url, type: mime.lookup(url) }],
          subtitles: subtitles,
        };

        setVideoPlayerData({
          ...blockData,
          options: { ...blockData.options, ...mediaData },
        });

        setVideoBodyProps({
          parentUid: mediaPage.UID,
          parentType: mediaPage['@type'],
          parentItems: children,
        });
      }
    };

    updatePlayerData();
  }, [
    token,
    fetchMediaPageByUid,
    fetchChildren,
    fetchAndCreateBlobUrl,
    fetchSubtitles,
  ]);

  const ExpandableText = ({ text, lines = 2, isTitle = false }) => {
    const [expanded, setExpanded] = useState(false);

    return (
      <div
        className="ExpandableText"
        style={{
          display: '-webkit-box',
          WebkitBoxOrient: 'vertical',
          overflow: 'hidden',
          WebkitLineClamp: expanded ? 'unset' : lines,
          cursor: 'pointer',
          marginBottom: '10px',
        }}
        onClick={() => setExpanded(!expanded)}
        onKeyDown={(e) => {
          if (e.key === 'Enter' || e.key === ' ') {
            setExpanded(!expanded);
          }
        }}
        tabIndex={0} // Mahdollistaa näppäimistöllä kohdistamisen
        role="button" // Määrittää elementin painikkeeksi saavutettavuuden kannalta
      >
        {isTitle ? <h2>{text}</h2> : <p>{text}</p>}
      </div>
    );
  };

  return (
    <div id="page-embed">
      <div id="player-container">
        {videoPlayerData && videoBodyProps && (
          <div className="block video align center">
            <Body {...videoBodyProps} data={videoPlayerData} editMode={false} />
          </div>
        )}

        {audioSrc && (
          <div className="EmbedAudioPlayer">
            <div className={`MobileView ${isMobilePlayerOpen ? 'hidden' : ''}`}>
              <div className="Image-field">
                <img
                  src={videoImage.download}
                  className="AudioplayerImage"
                  alt=""
                />
              </div>
              <div className="TitleDescription-container MobileplayerClosed">
                {videoTitle && (
                  <ExpandableText text={videoTitle} lines={1} isTitle />
                )}
                {videoDescription && (
                  <ExpandableText text={videoDescription} lines={2} />
                )}
                <AudioDurationDisplay audioRef={audioRef} />
              </div>
              <button className="OpenPlayerButton" onClick={handleOpenPlayer}>
                <img className="Play-icon" src={PlayIcon} alt="Play/Pause" />
              </button>
            </div>

            {/* Mobiilisoitin: Näytetään vain, jos soitin on auki */}
            <div
              className={`MobilePlayer ${isMobilePlayerOpen ? '' : 'hidden'}`}
            >
              <div className="AudioPlayer">
                <div className="TitleDescription-container MobilePlayeropen">
                  <div className="Title-container">
                    {videoTitle && (
                      <ExpandableText text={videoTitle} lines={1} isTitle />
                    )}
                  </div>
                  <button
                    className="ClosePlayerButton"
                    onClick={() => setIsMobilePlayerOpen(false)}
                  >
                    ✖
                  </button>
                  <div className="Description-container">
                    {videoDescription && (
                      <ExpandableText text={videoDescription} lines={2} />
                    )}
                  </div>
                </div>
                <audio ref={audioRef} src={audioSrc}>
                  <track kind="captions" src="" />
                </audio>

                <div className="SeekBarWrapper">
                  <PlayPauseButton
                    className="MobilePlayButton"
                    audioRef={audioRef}
                  />
                  <SeekBar audioRef={audioRef} />
                </div>

                <div className="Controls-container">
                  <PlayPauseButton
                    className="DesktopPlayButton"
                    audioRef={audioRef}
                  />
                  <VolumeControl audioRef={audioRef} />
                  <CurrentTimeDisplay audioRef={audioRef} />{' '}
                  {/* Näyttää nykyisen ajan */}
                  <SpeedControl audioRef={audioRef} />
                </div>
              </div>
            </div>

            <div className="DesktopPlayer">
              <div className="Image-field">
                <img
                  src={videoImage.download}
                  className="AudioplayerImage"
                  alt=""
                />
              </div>
              <div className="AudioPlayer">
                <div className="TitleDescription-container">
                  {videoTitle && (
                    <ExpandableText text={videoTitle} lines={1} isTitle />
                  )}
                  <div className="Description-container">
                    {videoDescription && (
                      <ExpandableText text={videoDescription} lines={2} />
                    )}
                  </div>
                </div>
                <audio ref={audioRef} src={audioSrc}>
                  <track kind="captions" src="" />
                </audio>

                <div className="Player-controls">
                  <div className="SeekBarWrapper">
                    <PlayPauseButton
                      className="MobilePlayButton"
                      audioRef={audioRef}
                    />
                    <SeekBar audioRef={audioRef} />
                  </div>

                  <div className="Controls-container">
                    <PlayPauseButton
                      className="DesktopPlayButton"
                      audioRef={audioRef}
                    />
                    <VolumeControl audioRef={audioRef} />
                    <SpeedControl audioRef={audioRef} />
                    <CurrentTimeDisplay audioRef={audioRef} />{' '}
                    {/* Näyttää nykyisen ajan */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Embed;
