const SpeedControl = ({ audioRef }) => {
  const handleSpeedChange = (event) => {
    audioRef.current.playbackRate = event.target.value;
  };

  return (
    <select onChange={handleSpeedChange} className="SpeedControl">
      <option value="0.5">0.5x</option>
      <option value="1" selected>
        1x
      </option>
      <option value="1.5">1.5x</option>
      <option value="2">2x</option>
    </select>
  );
};

export default SpeedControl;
