import React from 'react';
import { Segment, Tab } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import Icon from '@plone/volto/components/theme/Icon/Icon';
import mime from 'mime-types';
import omit from 'lodash/omit';
import { getContent } from '@plone/volto/actions/content/content';
import UrlWidget from '@plone/volto/components/manage/Widgets/UrlWidget';
import addSVG from '@plone/volto/icons/circle-plus.svg';
import clearSVG from '@plone/volto/icons/clear.svg';
import { flattenToAppURL, isInternalURL } from '@plone/volto/helpers/Url/Url';

const Source = (props) => {
  const { block, data, onChangeBlock, refreshPlayer, refreshSidebar } = props;

  const dispatch = useDispatch();

  const addSource = () => {
    if (data.options.sources?.length) {
      onChangeBlock(block, {
        ...data,
        options: {
          ...data.options,
          sources: [...data.options.sources, { src: '', type: '' }],
        },
      });
    } else {
      onChangeBlock(block, {
        ...data,
        options: {
          ...data.options,
          sources: [{ src: '', type: '' }],
        },
      });
    }
  };

  const deleteSource = (index) => {
    if (index === 0 && data.options.sources.length === 1) {
      onChangeBlock(block, {
        ...data,
        options: {},
      });
    } else {
      onChangeBlock(block, {
        ...data,
        options: {
          ...data.options,
          sources: [
            ...data.options.sources.slice(0, index),
            ...data.options.sources.slice(index + 1),
          ],
        },
      });
    }

    refreshSidebar();
    refreshPlayer();
  };

  const onChange = (index) => async (id, value) => {
    if (
      index === 0 &&
      value === undefined &&
      data.options.sources.length === 1
    ) {
      onChangeBlock(block, {
        ...omit(data, ['player']),
        options: {
          sources: [{ src: '', type: '' }],
        },
      });
    } else {
      if (mime.lookup(value)) {
        onChangeBlock(block, {
          ...data,
          options: {
            ...data.options,
            sources: [
              ...data.options.sources.slice(0, index),
              {
                src:
                  value && isInternalURL(value)
                    ? value.includes('@@download')
                      ? value
                      : `${flattenToAppURL(value)}/@@download/file`
                    : value,
                type: mime.lookup(value),
              },
              ...data.options.sources.slice(index + 1),
            ],
          },
        });
      } else {
        // Here's a possibility that we have a media page.
        // In that case we will convert the path to UID
        // for more robusteness for example
        // when moving content.
        if (isInternalURL(value)) {
          const contentUrl = flattenToAppURL(value);
          await dispatch(getContent(contentUrl, null, 'compare_to', null))
            .then(async (content) => {
              if (content !== null && content['@type'] === 'media_page') {
                onChangeBlock(block, {
                  ...data,
                  url: `/resolveuid/${content['UID']}`,
                  options: {
                    ...data.options,
                    sources: [
                      ...data.options.sources.slice(0, index),
                      {
                        src: content['UID'],
                        type: 'UID',
                      },
                      ...data.options.sources.slice(index + 1),
                    ],
                  },
                });
              }
            })
            .catch((err) => {
              // eslint-disable-next-line no-console
              console.log(err);
            });
        } else {
          onChangeBlock(block, {
            ...data,
            options: {
              ...data.options,
              sources: [
                ...data.options.sources.slice(0, index),
                {
                  src: value,
                  type: mime.lookup(value),
                },
                ...data.options.sources.slice(index + 1),
              ],
            },
          });
        }
      }
    }
  };

  return (
    <Tab.Pane>
      <p className="help">
        {`An array of objects that mirror the native <video> element's capability to have a series of child <source> elements.
It is commonly used to offer the same media content in multiple file formats in order to provide compatibility with a broad range of browsers given their differing support for media file formats.`}
      </p>
      {data.options?.sources?.map((source, index) => {
        return (
          <Segment key={index}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
              }}
            >
              <button
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  fontSize: '0.9em',
                  cursor: 'pointer',
                }}
                onClick={() => deleteSource(index)}
              >
                <i style={{ position: 'relative', top: '2px' }}>
                  <Icon
                    name={clearSVG}
                    title="Delete"
                    size="2em"
                    color="#e40166"
                  />
                </i>
              </button>
            </div>
            <UrlWidget
              id={`source-` + index}
              value={data.options.sources[index].src}
              onChange={onChange(index)}
              key={`source-` + index}
              block={block}
              title={`Source ${index + 1}`}
            />
          </Segment>
        );
      })}
      <Segment
        textAlign="center"
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          cursor: 'pointer',
          fontSize: '0.9em',
          height: '46px',
        }}
        onClick={addSource}
      >
        <FormattedMessage id="Add source" defaultMessage="Add source" />
        <i style={{ marginLeft: '3px', position: 'relative', top: '3px' }}>
          <Icon name={addSVG} size="32px" title="Add source" />
        </i>
      </Segment>
    </Tab.Pane>
  );
};

export default Source;
