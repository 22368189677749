/**
 * A Block View that wraps VideoJS Block View and adds some mediapage specific functionalities.
 * @module components/manage/Blocks/MediaPageVideoJS
 */

import { useUIDVariablesPoll } from 'volto-epics-addon/helpers';
import React, { useEffect, useState, useRef, useCallback } from 'react';
import { Progress, Loader } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import Body from '../VideoJS/Body';
import { withBlockExtensions } from '@plone/volto/helpers/Extensions';
import cx from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import { getContent } from '@plone/volto/actions/content/content';
import { flattenToAppURL } from '@plone/volto/helpers/Url/Url';
import { useWorkflowStatePolling } from '@package/helpers/WorkflowHook/WorkflowHook';
import { useVideoPlayerData } from '@package/components/manage/Blocks/VideoJS/VideoPlayerDataHook';
import { useIntl, defineMessages } from 'react-intl';
import { useLiveActivePoll } from '../VideoJS/LiveActivePollHook';
import VideoPlaceholder from '../VideoJS/VideoPlaceholder';
import FormattedDate from '@plone/volto/components/theme/FormattedDate/FormattedDate';
import { dateTimeStringAppendZ } from '@package/helpers/utils';
import { getWorkflow } from '@plone/volto/actions/workflow/workflow';

const messages = defineMessages({
  subtitlesProcessing: {
    id: 'subtitlesProcessing',
    defaultMessage: 'Subtitles processing...',
  },
  subtitlesLanguage: {
    id: 'subtitlesLanguage',
    defaultMessage: 'Subtitles language',
  },
  subtitlesTranslation: {
    id: 'subtitlesTranslation',
    defineMessage: 'Subtitles translation to English...',
  },
  liveStartsAt: {
    id: 'liveStartsAt',
    defaultMessage: 'Live stream starts at',
  },
  liveStartsSoon: {
    id: 'liveStartsSoon',
    defaultMessage: 'Live stream will start soon',
  },
  liveEndedAt: {
    id: 'liveEndedAt',
    defaultMessage: 'Live stream ended at',
  },
  liveNotStarted: {
    id: 'liveNotStarted',
    defaultMessage: 'Live stream has not started yet',
  },
  videoUnavailable: {
    id: 'videoUnavailable',
    defaultMessage: 'Video is currently unavailable.',
  },
});

// These are the profiles that the block will try query about
// (they also define the order in which the elements will be
// rendered)
const profiles = ['Audio', '360p', '720p', '1080p', '2160p'];

// This probably should not be the master of this information
const titles = {
  Audio: 'Audio only',
  '360p': '360p',
  '720p': '720p',
  '1080p': '1080p',
  '2160p': '2160p',
};

const keyFromItem = (item) => {
  if (item['@type'] === 'transcoding') {
    return item['title'];
  }
  if (item['@type'] === 'subtitle') {
    try {
      const urlParts = item['@id'].split('/');
      const id = urlParts[urlParts.length - 1];
      return id.split('-')[0];
    } catch {}
  }
  return null;
};

const View = (props) => {
  const dispatch = useDispatch();
  const intl = useIntl();

  const { data, className } = props;

  const content = useSelector((state) => state.content.data);
  const live_workflow_state = useSelector(
    (state) => state.workflow.chain?.live_stream_workflow?.state,
  );

  const [playerDataFailed, setPlayerDataFailed] = useState(false);
  const playerData = useVideoPlayerData(
    content.items,
    content.s3_object_id,
    content.live_stream_url,
    content.UID,
    () => {
      setPlayerDataFailed(true);
    },
  );

  const [liveShouldPoll, setLiveShouldPoll] = useState(true);
  const [liveForceAutoplay, setLiveForceAutoplay] = useState(false);

  const isLiveStream = !!content && live_workflow_state?.id === 'active';

  const liveActiveCallback = useCallback(() => {
    setLiveShouldPoll(false);
  }, []);

  const isLiveActive = useLiveActivePoll(
    content?.live_stream_url,
    isLiveStream && liveShouldPoll,
    10000,
    liveActiveCallback,
  );

  const itemsRef = useRef(content.items);

  let playerState = 'WAITING';
  if (content['s3_object_id'] === null || content['s3_object_id'] === '') {
    playerState = 'NOT_INITIALIZED';
  }
  if (Object.keys(playerData).length) {
    playerState = 'INITIALIZED';
  }
  if (playerDataFailed) {
    playerState = 'FAILED';
  }

  // Poll workflowState as long as there are in_progress items
  const workflowState = useWorkflowStatePolling(content.items, keyFromItem);

  useEffect(() => {
    dispatch(getWorkflow(flattenToAppURL(content['@id'])));
  }, [dispatch, content]);

  // Sets a ref for async functions
  useEffect(() => {
    itemsRef.current = content.items;
  }, [content.items]);

  // Hopefully get up-to-date content subitems after a small delay
  useEffect(() => {
    const timer = setTimeout(() => {
      dispatch(getContent(flattenToAppURL(content['@id'])));
    }, 3000);
    return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  // Update completedItems and failedItems from workflow information
  const completedItems = [];
  const failedItems = [];

  for (const key of Object.keys(workflowState)) {
    const state = workflowState[key]?.state;
    if (state === 'completed') {
      completedItems.push(key);
    }
    if (state === 'failed') {
      failedItems.push(key);
    }
  }

  // Poll for progress variables from camunda if needed
  let progressShouldPoll = true;
  const progressItems = itemsRef.current.filter(
    (item) => item['@type'] === 'transcoding' || item['@type'] === 'subtitle',
  );
  if (completedItems.length + failedItems.length === progressItems.length) {
    progressShouldPoll = false;
  }

  const progressVariables = useUIDVariablesPoll(
    5000,
    content.UID,
    ['transcodingProgress', 'transcriptionProgress', 'duration'],
    progressShouldPoll,
  );

  // At some specific completions, reload the page.
  if (playerState === 'NOT_INITIALIZED') {
    const transcodingItems = itemsRef.current
      .filter((item) => item['@type'] === 'transcoding')
      .map((item) => item['title']);

    // If only audio transcoding present and audio is completed, refresh
    if (
      !transcodingItems.includes('360p') &&
      completedItems.includes('Audio')
    ) {
      window.location.reload();
    }

    // If 360p has been completed, reload.
    if (completedItems.includes('360p')) {
      window.location.reload();
    }
  }

  let transcodingProgress = null;
  if (progressVariables != null) {
    const transcodingProgressRaw = {};

    for (const key in progressVariables) {
      if (progressVariables.hasOwnProperty(key)) {
        if (
          Object.keys(progressVariables[key]).includes('transcodingProgress')
        ) {
          const transcodingProgress =
            progressVariables[key].transcodingProgress;
          const progressObject = JSON.parse(transcodingProgress);
          for (const profile in progressObject) {
            if (progressObject.hasOwnProperty(profile)) {
              const currentTime = progressObject[profile].current_time;
              transcodingProgressRaw[profile] = { current_time: currentTime };
            }
          }
        }
      }
    }

    const duration =
      progressVariables[Object.keys(progressVariables)[0]].duration;

    transcodingProgress = {};
    Object.keys(transcodingProgressRaw).forEach((key) => {
      const current = parseFloat(transcodingProgressRaw[key]['current_time']);
      if (!(completedItems.includes(key) || failedItems.includes(key))) {
        transcodingProgress[key] = Math.round((current / duration) * 100);
      }
    });
  }

  let transcriptionProgress = null;
  if (progressVariables != null) {
    const flattened = Object.assign([], ...Object.values(progressVariables));

    const transcriptionProgressRaw = JSON.parse(
      flattened['transcriptionProgress'] ?? '{}',
    );

    transcriptionProgress = {};
    Object.keys(transcriptionProgressRaw).forEach((key) => {
      if (!(completedItems.includes(key) || failedItems.includes(key))) {
        transcriptionProgress[key] = {
          ...transcriptionProgressRaw[key],
        };
      }
    });
  }

  let modifiedPlayerData = null;
  if (Object.keys(playerData).length) {
    modifiedPlayerData = {
      ...data,
      options: {
        ...data.options,
        ...playerData,
      },
    };
  }

  if (isLiveStream) {
    if (modifiedPlayerData?.options) {
      modifiedPlayerData.options.playbackRates = null;

      // Force autoplay if user has already tried to play but there was an error due to live not being active yet
      if (liveForceAutoplay && isLiveActive) {
        modifiedPlayerData.options.autoplay = true;
      }
    }
  }

  const getPlaceholderMessage = () => {
    if (isLiveStream) {
      let start = content.live_stream_start
        ? new Date(dateTimeStringAppendZ(content.live_stream_start))
        : null;
      let end = content.live_stream_end
        ? new Date(dateTimeStringAppendZ(content.live_stream_end))
        : null;
      const now = new Date();

      if (end && now > end) {
        return (
          <>
            {intl.formatMessage(messages.liveEndedAt)}{' '}
            <FormattedDate date={end} includeTime></FormattedDate>
          </>
        );
      }
      if (start) {
        if (now < start) {
          return (
            <>
              {intl.formatMessage(messages.liveStartsAt)}{' '}
              <FormattedDate date={start} includeTime></FormattedDate>
            </>
          );
        }
        return intl.formatMessage(messages.liveStartsSoon);
      }
      return intl.formatMessage(messages.liveNotStarted);
    } else {
      return intl.formatMessage(messages.videoUnavailable);
    }
  };

  return (
    <>
      {modifiedPlayerData && (!isLiveStream || isLiveActive) ? (
        <div
          className={cx(
            'block video align',
            {
              center: !Boolean(data.align),
            },
            data.align,
            className,
          )}
        >
          <Body
            {...props}
            parentUid={props.properties?.UID}
            parentType={props.properties?.['@type']}
            parentItems={props.properties?.items}
            data={modifiedPlayerData}
            editMode={false}
            onError={(error) => {
              if (error.code === 4 && isLiveStream) {
                setLiveShouldPoll(true);
                setLiveForceAutoplay(true);
              }
            }}
            onXhrResponse={(request, error, response) => {
              if (error || response?.statusCode !== 200) {
                if (isLiveStream) {
                  setLiveShouldPoll(true);
                  setLiveForceAutoplay(true);
                }
              }
            }}
          />
        </div>
      ) : isLiveStream ? (
        <VideoPlaceholder>{getPlaceholderMessage()}</VideoPlaceholder>
      ) : null}
      {/* Show throbber if no player or no processing bars (for transcoding) */}
      {(((!transcodingProgress ||
        Object.keys(transcodingProgress).length === 0) &&
        playerState === 'NOT_INITIALIZED') ||
        playerState === 'WAITING') &&
      !isLiveStream ? (
        <div
          className="mediapage-before-progress"
          style={{ textAlign: 'center' }}
        >
          <Loader active inline />
        </div>
      ) : null}
      {/* Transription progress */}
      {transcriptionProgress?.['transcribe'] ? (
        <div className="mediapage-transcription-progress-indicator">
          <div key={'transcribe'}>
            {transcriptionProgress['transcribe']['detectedLanguage'] ? (
              <div>
                {intl.formatMessage(messages.subtitlesLanguage)}
                {': '}
                {transcriptionProgress['transcribe']['detectedLanguage']}
              </div>
            ) : (
              <div>{intl.formatMessage(messages.subtitlesProcessing)}</div>
            )}
            <Progress
              percent={transcriptionProgress['transcribe']['percentage']}
              indicating
              progress
            />
          </div>
        </div>
      ) : null}
      {/* Translation progress */}
      {transcriptionProgress?.['translate'] ? (
        <div className="mediapage-transcription-progress-indicator">
          <div key={'translate'}>
            <div>{intl.formatMessage(messages.subtitlesTranslation)}</div>
            <Progress
              percent={transcriptionProgress['translate']['percentage']}
              indicating
              progress
            />
          </div>
        </div>
      ) : null}
      {/* Transcoding progress */}
      <div className="mediapage-transcoding-progress-indicator">
        {profiles.map((profile) =>
          transcodingProgress?.[profile] ? (
            <div key={profile}>
              <div> {titles[profile]} </div>
              <Progress
                percent={transcodingProgress[profile]}
                indicating
                progress
              />
            </div>
          ) : null,
        )}
      </div>
    </>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
View.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default withBlockExtensions(View);
