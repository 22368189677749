import React from 'react';
import { Accordion, Button, Input, Radio, Segment } from 'semantic-ui-react';
import Icon from '@plone/volto/components/theme/Icon/Icon';
import { FormFieldWrapper } from '@plone/volto/components/manage/Widgets';
import rightSVG from '@plone/volto/icons/right-key.svg';
import downSVG from '@plone/volto/icons/down-key.svg';
import AnimateHeight from 'react-animate-height';
import CheckboxWidget from '@plone/volto/components/manage/Widgets/CheckboxWidget';
import FileWidget from '@plone/volto/components/manage/Widgets/FileWidget';
import TextWidget from '@plone/volto/components/manage/Widgets/TextWidget';
import topLeftSVG from '~/icons/top-left.svg';
import topRightSVG from '~/icons/top-right.svg';
import bottomLeftSVG from '~/icons/bottom-left.svg';
import bottomRightSVG from '~/icons/bottom-right.svg';

const Watermark = (props) => {
  const {
    block,
    data,
    currentActiveFieldset,
    handleCurrentActiveFieldset,
    refreshPlayer,
    onChangeBlock,
  } = props;

  const ICON_MAP = {
    'top-left': topLeftSVG,
    'top-right': topRightSVG,
    'bottom-left': bottomLeftSVG,
    'bottom-right': bottomRightSVG,
  };

  return (
    <div
      key="watermark"
      id="blockform-fieldset-watermark"
      className="watermark"
    >
      <Accordion.Title
        active={currentActiveFieldset === 3}
        index={3}
        onClick={handleCurrentActiveFieldset}
      >
        <>Watermark</>
        {currentActiveFieldset === 3 ? (
          <Icon name={downSVG} size="20px" />
        ) : (
          <Icon name={rightSVG} size="20px" />
        )}
      </Accordion.Title>
      <Accordion.Content active={currentActiveFieldset === 3}>
        <AnimateHeight
          animateOpacity
          duration={500}
          height={currentActiveFieldset === 3 ? 'auto' : 2}
        >
          <CheckboxWidget
            id="watermark-enabled"
            key="watermark-enabled"
            title="Enable"
            value={data.options?.watermark?.enabled}
            onChange={(id, value) => {
              onChangeBlock(block, {
                ...data,
                options: {
                  ...data.options,
                  watermark: {
                    position: 'top-right',
                    type: 'text',
                    text: 'Watermark',
                    opacity: 0.4,
                    enabled: value,
                  },
                },
              });
              refreshPlayer();
            }}
            block={block}
            description="Display a text/image watermark"
          />
          <Segment
            className="attached"
            style={{
              display: data.options?.watermark?.enabled ? 'block' : 'none',
            }}
          >
            <FormFieldWrapper id="position" title="Position">
              <div className="align-watermark">
                <Button.Group>
                  {Object.keys(ICON_MAP).map((action) => (
                    <Button
                      key={action}
                      icon
                      basic
                      aria-label={action}
                      onClick={() => {
                        onChangeBlock(block, {
                          ...data,
                          options: {
                            ...data.options,
                            watermark: {
                              ...data.options.watermark,
                              position: action,
                            },
                          },
                        });
                        refreshPlayer();
                      }}
                      active={data.options?.watermark?.position === action}
                    >
                      <Icon name={ICON_MAP[action]} size="17px" />
                    </Button>
                  ))}
                </Button.Group>
              </div>
            </FormFieldWrapper>
            <FormFieldWrapper id="type" title="Type">
              <div className="type-wrapper">
                <Radio
                  id="field-radio-text"
                  name={`field-radio-text`}
                  checked={data.options?.watermark?.type === 'text'}
                  value="text"
                  onChange={(event, { value }) => {
                    onChangeBlock(block, {
                      ...data,
                      options: {
                        ...data.options,
                        watermark: {
                          ...data.options.watermark,
                          type: value,
                        },
                      },
                    });
                    refreshPlayer();
                  }}
                  label={<label htmlFor={`field-radio-text`}>Text</label>}
                />
                <Radio
                  id="field-radio-img"
                  name={`field-radio-img`}
                  checked={data.options?.watermark?.type === 'img'}
                  onChange={(event, { value }) => {
                    onChangeBlock(block, {
                      ...data,
                      options: {
                        ...data.options,
                        watermark: {
                          ...data.options.watermark,
                          type: value,
                        },
                      },
                    });
                    refreshPlayer();
                  }}
                  value="img"
                  label={<label htmlFor={`field-radio-img`}>Image</label>}
                />
              </div>
            </FormFieldWrapper>
            <div
              style={{
                display:
                  data.options?.watermark?.type === 'text' ? 'block' : 'none',
              }}
            >
              <TextWidget
                id="watermark-type-text-input"
                title="Text"
                value={data.options?.watermark?.text}
                onChange={(id, value) => {
                  onChangeBlock(block, {
                    ...data,
                    options: {
                      ...data.options,
                      watermark: {
                        ...data.options.watermark,
                        text: value,
                      },
                    },
                  });
                  refreshPlayer();
                }}
              />
            </div>
            <div
              style={{
                display:
                  data.options?.watermark?.type === 'img' ? 'block' : 'none',
              }}
            >
              <FileWidget
                id="watermark-type-img-input"
                title="Image"
                value={data.options?.watermark?.image}
                onChange={(id, value) => {
                  if (value) {
                    onChangeBlock(block, {
                      ...data,
                      options: {
                        ...data.options,
                        watermark: {
                          ...data.options.watermark,
                          image: value,
                        },
                      },
                    });
                    refreshPlayer();
                  } else {
                    delete data.options?.watermark?.image;
                    onChangeBlock(block, {
                      ...data,
                      options: {
                        ...data.options,
                        watermark: {
                          ...data.options.watermark,
                        },
                      },
                    });
                  }
                }}
                key="watermark-type-img-input"
                block={block}
              />
            </div>
            <div
              style={{
                display: data.options?.watermark?.enabled ? 'block' : 'none',
              }}
            >
              <FormFieldWrapper id="watermark-opacity" title="Opacity">
                <Input
                  min={0}
                  max={1}
                  name="watermark-opacity-range"
                  onChange={(event, { value }) => {
                    onChangeBlock(block, {
                      ...data,
                      options: {
                        ...data.options,
                        watermark: {
                          ...data.options.watermark,
                          opacity: value,
                        },
                      },
                    });
                    refreshPlayer();
                  }}
                  step={0.1}
                  type="range"
                  value={data.options?.watermark?.opacity || 0}
                />
              </FormFieldWrapper>
            </div>
          </Segment>
        </AnimateHeight>
      </Accordion.Content>
    </div>
  );
};

export default Watermark;
