import React, { useState, useEffect } from 'react';

const AudioDurationDisplay = ({ audioRef }) => {
  const [duration, setDuration] = useState(0);

  useEffect(() => {
    const audio = audioRef.current;
    if (!audio) return;

    const updateDuration = () => {
      if (!isNaN(audio.duration)) {
        setDuration(audio.duration);
      }
    };

    audio.addEventListener('loadedmetadata', updateDuration);

    return () => {
      audio.removeEventListener('loadedmetadata', updateDuration);
    };
  }, [audioRef]);

  // Muodostetaan ajan näyttö muodossa h:mm:ss
  const formatTime = (time) => {
    const hours = Math.floor(time / 3600);
    const minutes = Math.floor((time % 3600) / 60);
    const seconds = Math.floor(time % 60);

    if (hours > 0) {
      return `${hours} h ${minutes} min ${seconds} s`;
    } else if (minutes > 0) {
      return `${minutes} min ${seconds} s`;
    } else {
      return `${seconds} s`;
    }
  };

  return <span className="AudioDuration">{formatTime(duration)}</span>;
};

export default AudioDurationDisplay;
